import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { VscEdit } from "react-icons/vsc";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
// import qrcodeIcon from "../../../assets/icons/qr_code_icon.svg";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import Select from "react-select";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../Action/ConferenceListApi";
import { ToastError, ToastSuccess } from "../../Components/Toast/index.jsx";
import TableHeader from "../../../TableHeader.json";
// import Axios from "../../../Utils/AxiosConfig.jsx";

const DeletePopup = lazy(() =>
  import("../../Components/DeletePopup/index.jsx")
);
const NewLIstTable = lazy(() =>
  import("../../Components/NewLIstTable/index.jsx")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: "50%",
  width: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: 0,
};

function EventListMain() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [conferenceId, setConferenceId] = useState();
  const [eventId, setEventId] = useState();
  const [open, setOpen] = useState(false);
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState("");
  const [filterPage, setFilterPage] = useState(false);
  const [syncCheck, setSyncCheck] = useState([]);

  const qrRef = useRef();

  // let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  useEffect(() => {
    document.title = "FIG Events | Event";
    getConfList();
    // eslint-disable-next-line
  }, []);
  const logindetail = useSelector((state) => state.login.LoginDetails);
 const token = useSelector((state) => state.login.LoginDetails.accessToken);

  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConList(res);
      handleConfereceSelect(res[0]?.value);
    }
  };

  useEffect(() => {
    if (confId) {
      GetAllEventsList();
    }
  }, [confId]);


  const changetbData = () => {
    let newtb = dataOfTable?.map((pb) => {
      return {
        ...pb,
        Action: (
          <>
            <label className="personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                name="Remember Me"
                checked={
                  syncCheck?.filter((x) => x === pb?.eventId)?.length > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleSelectSync(e?.target?.checked, pb?.eventId)
                }
              />
              <span className="checkmark"></span>
            </label>
            <Button
              className="download_btn me-3"
              onClick={() => handleDownload(pb)}
              style={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "space-between",
                background:
                  "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                padding: "5px 15px",
                borderRadius: "5px",
                color: "white",
                textTransform: "none",
              }}
            >
              <div className="text">
                <img src={"/assets/icons/qr_code_icon.svg"} alt="QR Code" />
                <span className="ms-2">Download QR</span>
              </div>
            </Button>
            <Link
              to={`edit/${pb.eventId}`}
              className="btn_edit me-3 d-flex align-items-center"
            >
              <VscEdit />
            </Link>
            <DeletePopup
              title={"Delete Event"}
              text={pb?.eventName}
              mtd={"post"}
              url={`/api/Events/DeleteEvents?eventIds=${pb?.eventId}&deletedBy=${logindetail?.userId}`}
              callBack={true}
              callBackFunction={GetAllEventsList}
            />
          </>
        ),
      };
    });
    setDataOfTable(newtb);
  };

  useEffect(() => {
    if (syncCheck?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [syncCheck]);
  const GetAllEventsList = async () => {
    setDataOfTable([]);
     setSyncCheck([]);
    if (confId) {
      setOpenCircle(true);
      let seData = {
        url: `/api/Events/GetAllEventsList?userId=${logindetail?.userId}&conferenceId=${confId}`,
      };
      let res = await GetCallApi(seData);
      setOpenCircle(false);
      setFilterPage(!filterPage);

      if (res?.status === 200 && res?.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            eventStartdate: c?.eventStartdate
              ? convert(c?.eventStartdate?.split("T")[0])
              : "",
            eventEnddate: c?.eventEnddate
              ? convert(c?.eventEnddate?.split("T")[0])
              : "",
            status: c?.status,
            Action: (
              <>
                <label className="personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={false}
                    onChange={(e) =>
                      handleSelectSync(e?.target?.checked, c?.eventId)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <Button
                  className="download_btn me-3"
                  onClick={() => handleDownload(c)}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    background:
                      "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "white",
                    textTransform: "none",
                  }}
                >
                  <div className="text">
                    <img src={"/assets/icons/qr_code_icon.svg"} alt="QR Code" />
                    <span className="ms-2">Download QR</span>
                  </div>
                </Button>
                <Link
                  to={`edit/${c.eventId}`}
                  className="btn_edit me-3 d-flex align-items-center"
                >
                  <VscEdit />
                </Link>
                <DeletePopup
                  title={"Delete Event"}
                  text={c?.eventName}
                  mtd={"post"}
                  url={`/api/Events/DeleteEvents?eventIds=${c?.eventId}&deletedBy=${logindetail?.userId}`}
                  callBack={true}
                  callBackFunction={GetAllEventsList}
                />
              </>
            ),
          };
        });
        setDataOfTable(comList);
      } else {
        ToastError(res?.data?.message);
      }
    }
  };

  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(qrRef.current);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadQRCode(image, imageFileName);
  };

  const downloadQRCode = async (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setOpen(false);
  };

  const handleDownload = (c) => {
    setEventId(c);
    setConferenceId(c?.conferenceId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfereceSelect = (e) => {
    setConfId(e);
  };

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const handleDelete = async () => {
    let eventData = syncCheck?.map((x) => x);
    let seAsso = {
      url: `/api/Events/DeleteMultipleEvents?deletedBy=${logindetail?.userId}`,
      body: eventData,
      headers: headers,
    };

    // let res = await Axios.delete(seAsso.url, {
    //   headers: seAsso.headers,
    //   data: seAsso.body,
    // });
    let res = await PostCallApi(seAsso);
    if (res?.status === 200 && res?.data.success) {
      ToastSuccess(res?.data.message);
      GetAllEventsList();
    } else {
      ToastError(res?.data.message);
    }
  };

  const handleSelectSync = (val, userId) => {
    if (val) {
      setSyncCheck((old) => [...old, userId]);
    } else {
      setSyncCheck((old) => old?.filter((data) => data !== userId));
    }
  };

  const handleSelectAll = (val, list) => {
    if (val) {
      setSyncCheck(list?.map((x) => x?.eventId));
    } else {
      setSyncCheck([]);
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className="d-flex flex-wrap gap-4 mb-3"
                      style={{ marginLeft: "20px" }}
                    >
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={conList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conList?.filter(
                              (obj) => obj.value === confId
                            )}
                            onChange={(e) => handleConfereceSelect(e?.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    addressLink={`create`}
                    headCells={TableHeader?.EventListHeader}
                    title="Event List"
                    action={true}
                    ListData={dataOfTable}
                    filterPage={filterPage}
                    addBtn={true}
                    actionFirst={true}
                    selectAllBtn={
                      logindetail?.roleId === 1 || logindetail?.roleId === 2
                        ? true
                        : false
                    }
                    selectingAll={handleSelectAll}
                    AddStudents={handleDelete}
                    prdAddAr={syncCheck}
                    checkBtnName={"Delete"}
                    className={"delet_button"}
                    dropdown={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {open && (
          <Modal
            sx={{ zIndex: 9 }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="popup_box_add_drag"
          >
            <Box sx={style} className={`main_box `}>
              <div ref={qrRef}>
                <div
                  className=" p-3 w-auto"
                  style={{ color: "#80c242", fontSize: "18px" }}
                >
                  {eventId?.eventName}
                </div>
                {/* <div className="title">QR Code</div> */}
                <hr />
                <div className="col-md-12 d-flex justify-content-center mb-3">
                  <div className="qr_box">
                    <div style={{ padding: "1rem" }} className="qr_image">
                      <QRCode
                        value={
                          "/conferenceId/" +
                          conferenceId +
                          "/Event/" +
                          eventId?.eventId
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-evenly">
                  <Button
                    className="back_button"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="common_btn"
                    onClick={(e) => exportAsImage(e, eventId?.eventName)}
                  >
                    Download
                  </Button>
                  {/* className="common_btn mx-auto" */}
                </div>
              </div>
              {/* </form> */}
            </Box>
          </Modal>
        )}
      </Suspense>
    </>
  );
}

export default EventListMain;
