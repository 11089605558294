import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import Axios from "../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";
import { Backdrop, CircularProgress } from "@mui/material";
import NewLIstTable from "../../Components/NewLIstTable/index.jsx";
const DeletePopup = lazy(() => import("../../Components/DeletePopup"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsMenu = [
  {
    id: "no",
    label: "#",
  },
  {
    id: "menuName",
    label: "Menu Name",
  },
  {
    id: "ParentMenuName",
    label: "Parent Menu Name",
  },
  {
    id: "Seno",
    label: "Sequence No",
  },
  {
    id: "menuType",
    label: "Menu Type",
  },
  {
    id: "url",
    label: "Menu Url",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "menuIcon",
    label: "Menu Icon",
  },
];

function MenuList() {
  let navigate = useNavigate();
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  useEffect(() => {
    GetMenuList();
    document.title = "FIG Events | Menu ";

    // eslint-disable-next-line
  }, [deleteInfo]);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const routeChange = (id) => {
    if (id) {
      navigate(`/menu-create`, { replace: true, state: { menuId: id } });
    } else {
      navigate("/menu-create", { replace: true });
    }
  };

  const GetMenuList = () => {
    setOpenCircle(true);
    Axios.get("/api/Menu/GetAllMenuData", { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data.success) {
            setOpenCircle(false);
            let comList = res?.data?.payload?.map((c, i) => {
              return {
                no: i + 1,
                Seno: c?.sequenceNumber,
                menuName: c?.menuName,
                url: c?.url,
                ParentMenuName: c?.parentMenuName,
                menuType: c?.menuType === 0 ? "Main Menu" : "Sub Menu",
                description: c?.menuDescription,
                menuIcon: (
                  <div className="icon_td">
                    <i className={`menu_icon fas fa-${c?.menuIcon}`}></i>
                    {c?.menuIcon}
                  </div>
                ),
                Action: (
                  <>
                    <Link
                      className="btn_edit me-3 d-flex align-items-center"
                      to={`/menu-edit/${c?.menuId}`}
                    >
                      <VscEdit />
                    </Link>
                    <DeletePopup
                      title={"Delete Menu"}
                      text={c?.menuName}
                      mtd={"post"}
                      url={`/api/Menu/DeleteMenuData?MenuId=${c?.menuId}&deletedBy=${LogDetails?.userId}`}
                    />
                  </>
                ),
              };
            });
            setDataOfTable1(comList);
          } else {
            setOpenCircle(false);
          }
        } else {
          setOpenCircle(false);
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <NewLIstTable
                  addressLink={`/menu-create`}
                  headCells={headCellsMenu}
                  title="Menu List"
                  action={true}
                  ListData={dataOfTable1}
                  dropdown={false}
                  addBtn={true}
                  onBtnClick={() => routeChange()}
                  as="no"
                />
              </div>
            </div>
            {/* <div className="card mb-3">
              <div className="card-body">
              </div>
            </div> */}
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default MenuList;
