import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
// import Axios from "../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";
import DeletePopup from "../../../Components/DeletePopup";
import ImgPopup from "../../../Components/ImgPopUp/index.jsx";
import { Config } from "../../../../Utils/Config";
import NewLIstTable from "../../../Components/NewLIstTable/index.jsx";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsSession = [
  {
    id: "no",
    label: "#",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "image",
    label: "Image",
  },
];

function Gallery() {
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  useEffect(() => {
    GetGlList();
    document.title = "FIG Events | Gallery";
    // eslint-disable-next-line
  }, [deleteInfo]);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const GetGlList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/Gallery/GetAllGallery`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            no: i + 1,
            ...c,
            image: c?.image ? (
              <ImgPopup
                img={`${Config.API_HOST_URL_live}${c.image}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : (
              ""
            ),
            Action: (
              <>
                <Link
                  type="button"
                  className="btn_edit me-3"
                  to={`/gallery-edit/${c?.galleryId}`}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <VscEdit />
                </Link>
                <DeletePopup
                  title={"Delete Gallery Image"}
                  text={c?.title}
                  mtd={"post"}
                  url={`/api/Gallery/DeleteGallery?galleryId=${c?.galleryId}&deletedBy=${LogDetail?.userId}`}
                />
              </>
            ),
          };
        });
        setDataOfTable1(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <NewLIstTable
                  addressLink={`/gallery-create`}
                  headCells={headCellsSession}
                  title="Gallery List"
                  action={true}
                  ListData={dataOfTable1}
                  dropdown={false}
                  addBtn={true}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default Gallery;
