import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { VscEdit } from "react-icons/vsc";
import "./index.scss";
import { GetCallApi } from "../../../../Action/Action";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../../Components/Toast";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../../Action/ConferenceListApi";

const NewLIstTable = lazy(() => import("../../../Components/NewLIstTable"));
const Togglebutton = lazy(() => import("../../../Components/Togglebutton"));
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCells = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "eventName",
    label: "Event Name",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "description",
    label: "Description",
    widthFix: 600,
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function PushNotification() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [apData, setApData] = useState();
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState("");
  const [filterPage, setFilterPage] = useState(false);

  const logindetail = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Push Notification";
    getConfList();
    // eslint-disable-next-line
  }, []);

  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConList(res);
    }
  };

  const handleOpenPop = (eId) => {
    setApData(eId);
    setOpenPop(true);
  };
  const handleClose = () => {
    setApData();
    setOpenPop(false);
  };

  useEffect(() => {
    GetAllNotificationList();
  }, [confId]);

  const GetAllNotificationList = async () => {
    setOpenCircle(true);
    setDataOfTable([]);
    let seData = {
      url: `/api/PublicNotification/GetAllPublicNotification?userId=${
        logindetail?.userId
      }&conferenceId=${confId ? confId : ""}`,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res?.status === 200 && res?.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          Action: (
            <>
              {c?.isActive && (
                <>
                  <button
                    type="button"
                    className="btn_edit me-3"
                    style={{ color: "#73c04d" }}
                    onClick={() => handleOpenPop(c)}
                  >
                    Resend
                  </button>
                  <Link
                    to={`edit/${c.publicNotificationId}`}
                    className="btn_edit me-3 d-flex align-items-center"
                  >
                    <VscEdit />
                  </Link>
                </>
              )}
              <Togglebutton
                checked={c?.isActive === true ? true : false}
                title={"Delete Push Notification"}
                disabled={false}
                text={c?.title}
                mtd={"post"}
                url={`/api/PublicNotification/DeletePublicNotification?publicNotificationId=${c?.publicNotificationId}&deletedBy=${logindetail?.userId}`}
                callBack={true}
                callBackFunction={GetAllNotificationList}
              />
            </>
          ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res?.data.message);
    }
  };

  const HandleRequest = async () => {
    let seData = {
      url: `/api/PublicNotification/ResendPublicNotificationById?publicNotificationId=${apData?.publicNotificationId}`,
    };
    setOpenCircle(true);
    let resp = await GetCallApi(seData);
    setOpenCircle(false);
    if (resp?.status === 200 && resp.data.success) {
      handleClose();
      ToastSuccess(resp.data.message);
    } else {
      ToastError(resp.data.message);
    }
  };

  const handleConfereceSelect = (e) => {
    setConfId(e);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className="d-flex flex-wrap gap-4 mb-3"
                      style={{ marginLeft: "20px" }}
                    >
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={conList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conList?.filter(
                              (obj) => obj.value === confId
                            )}
                            onChange={(e) => handleConfereceSelect(e?.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    addressLink={`create`}
                    headCells={headCells}
                    title="Push Notification List"
                    action={true}
                    ListData={dataOfTable}
                    filterPage={filterPage}
                    addBtn={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {openPop && (
          <>
            <Modal
              sx={{ zIndex: 9 }}
              open={openPop}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
            >
              <Box sx={style} className="delete_data_box">
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={openCircle}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <div className="popup_delete_detail_box">
                  <h6>Push Notification Resend </h6>
                  <form>
                    <div className="popup_delete_detail">
                      <p className="delete_text">
                        Are you sure you want to resend push notification of{" "}
                        <span>{apData?.title}?</span>
                      </p>

                      <button
                        type="button"
                        onClick={HandleRequest}
                        className="popup_btn delete"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="popup_btn cancel"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </Box>
            </Modal>
          </>
        )}
      </Suspense>
    </>
  );
}

export default PushNotification;
