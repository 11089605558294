import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
// import Axios from "../../../Utils/AxiosConfig.jsx";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
// import { VscEdit } from "react-icons/vsc";
import NewLIstTable from "../../Components/NewLIstTable/index.jsx";
import { GetCallApi } from "../../../Action/Action.jsx";
import ImgPopUp from "../../Components/ImgPopUp/index.jsx";
import { Config } from "../../../Utils/Config.jsx";
import { VscEdit } from "react-icons/vsc";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import { SwalMessage } from "../../Components/Toast";
import { IndustryDrop } from "../../Components/AssociationMember/index.jsx";

const DeletePopup = lazy(() => import("../../Components/DeletePopup"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCellsVendor = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "image",
    label: "Profile Image",
  },
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "lastName",
    label: "Last Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "companyName",
    label: "Company Name",
  },
];
function SalesRepresentative() {
  const [venderList, setVenderList] = useState([]);
  const [companyId, setCompanyId] = useState();

  const [openCircle, setOpenCircle] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Sales Representative";
    // eslint-disable-next-line
  }, []);

  const { handleSubmit, reset, setValue } = useForm();

  const GeVendorList = async (data) => {
    if (data?.CompanyId) {
      let seData = {
        url: data?.CompanyId
          ? `/api/TeamMembers/GetAllTeamMembersList?userId=${LogDetail?.userId}&companyId=${data?.CompanyId}`
          : `/api/TeamMembers/GetAllTeamMembersList?userId=${LogDetail?.userId}`,
      };
      setOpenCircle(true);
      let res = await GetCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200 && res?.data.success) {
        let sv = res?.data.payload?.map((x, i) => {
          return {
            id: i + 1,
            ...x,
            ...x?.tm,
            image: x?.tm?.image ? (
              <ImgPopUp
                img={`${Config.API_HOST_URL_live}${x?.tm.image}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : (
              ""
            ),
            Action: (
              <div className="d-flex align-items-center justify-content-center">
                <Link
                  to={`/vendor-edit/${x?.tm?.teamMembersId}`}
                  className="btn_edit me-2 d-flex align-items-center"
                >
                  <VscEdit />
                </Link>
                <DeletePopup
                  title={"Delete Team Member"}
                  text={x?.tm?.firstName + " " + x?.tm?.lastName}
                  mtd={"post"}
                  url={`/api/TeamMembers/DeleteTeamMembers?teamMembersId=${x?.tm?.teamMembersId}&deletedBy=${LogDetail?.userId}`}
                  callBack={true}
                  callBackFunction={() => GeVendorList(data)}
                />
              </div>
            ),
          };
        });
        setVenderList(sv);
      }
    } else {
      SwalMessage("Please select company");
    }
  };

  const handleCompany = (e) => {
    setValue("CompanyId", e);
    setCompanyId(e);
  };

  const handleClearFilter = () => {
    setCompanyId();
    setVenderList([]);
    reset();
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="col-md-12">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={handleSubmit(GeVendorList)}>
                    <div className="row ">
                      <div className="col-md-3">
                        <IndustryDrop
                          isMulti={false}
                          selectedIndustry={[companyId]}
                          handleChangeIndustry={(e) => handleCompany(e?.value)}
                        />
                      </div>

                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="common_btn ms-3"
                            type="submit"
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="col-md-12">
              <div className="accordian_card">
                <NewLIstTable
                  addressLink={`/vendor-create`}
                  exportBtn={false}
                  title="Team Members List"
                  headCells={headCellsVendor}
                  action={true}
                  ListData={venderList}
                  addBtn={true}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default SalesRepresentative;
