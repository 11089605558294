import React, { useEffect, useState } from "react";
import "./index.scss";
import { useFieldArray, useForm } from "react-hook-form";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { Box, Modal } from "@mui/material";
import { VscTrash } from "react-icons/vsc";
import { useSelector } from "react-redux";
import {
  //DeleteCallApi,
  GetCallApi,
  PostCallApi,
} from "../../../../Action/Action";
import { useParams } from "react-router";
import { HiOutlineEye } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { Config } from "../../../../Utils/Config";
import DeletePopup from "../../../Components/DeletePopup";
import { BiUpload } from "react-icons/bi";
// import { MdDelete } from "react-icons/md";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import { validateImage } from "../../../Components/Validation";
import { ToastError, ToastSuccess } from "../../../Components/Toast";
import { IndustryDrop } from "../../../Components/AssociationMember";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function ProductCreate() {
  const [openCircle, setOpenCircle] = useState(false);
  // const { setProductId, handleNext } = props;
  const [productId, setProductId] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "benefits",
  });
  const {
    fields: fieldArray2,
    append: AppendArray2,
    remove: RemoveArray2,
  } = useFieldArray({
    control,
    name: "ProductVideoUrl",
  });

  const [displayDoc, setDisplayDoc] = useState([]);
  // const [companyList, setCompanyList] = useState([]);
  const [mainImage, setMainImage] = useState();
  const [multiImages, setMultiImages] = useState([]);
  const [docsFile, setDocsFile] = useState([]);
  const [open, setOpen] = useState(false);
  const [matD, setMatD] = useState();
  const [derror, setDerror] = useState();
  const [upc, setUpc] = useState();
  const [ingred, setIngred] = useState();
  // const [ecDe, setEcDe] = useState();
  const [category, setCategory] = useState("Food Products");
  const [companyId, setCompanyId] = useState();
  const { id } = useParams();
  let nav = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const onSubmit = async (data) => {
    let sendData = { ...data, createdBy: LogDetail?.userId };
    delete sendData.SessionDoc1;
    if (!data?.CompanyId) {
      delete sendData?.CompanyId;
    }
    let Sessiondata = new FormData();

    Sessiondata.append(
      "upc",
      category === "Food Products"
        ? upc
          ? upc !== "null"
            ? upc
            : ""
          : ""
        : ""
    );
    Sessiondata.append(
      "ingredients",
      category === "Food Products"
        ? ingred
          ? ingred !== "null"
            ? ingred
            : ""
          : ""
        : ""
    );

    if (displayDoc?.length > 0) {
      displayDoc?.map((d) => {
        return Sessiondata.append("Documents", d.file);
      });
    } else {
      Sessiondata.append("Documents", []);
    }

    if (multiImages?.length > 0) {
      multiImages
        ?.filter((x) => x?.val === "new")
        ?.map((d) => {
          return Sessiondata.append("MultipleImage", d.file);
        });
    } else {
      Sessiondata.append("MultipleImage", []);
    }
    for (var key in sendData) {
      Sessiondata.append(key, sendData[key]);
    }
    let seData = {
      url: `/api/ExhibitorProduct/CreateExhibitorProduct`,
      body: Sessiondata,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    };
    setOpenCircle(true);
    setDerror({ err: "" });

    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200 && res?.data.success) {
      // let pv = data?.ProductVideoUrl?.filter(
      //   (x) => x?.fileName || x?.videoLink
      // );
      // if (pv?.length > 0) {
      let sData1 = {
        url: `/api/ExhibitorProduct/AddExhibitorProductVideoLink`,
        body: {
          ProductVideoUrl: data?.ProductVideoUrl,

          createdBy: LogDetail?.userId,
          exhibitorProductId: res?.data?.payload?.exhibitorProductId,
        },
      };
      // let resp1 =
      await PostCallApi(sData1);
      // }
      // if (resp1?.status === 200) {
      //   if (resp1?.data.payload) {
      //   }
      // }
      let sData = {
        url: `/api/ExhibitorProduct/CreateProductBenefits`,
        body: {
          ...data,
          createdBy: LogDetail?.userId,
          exhibitorProductId: res?.data?.payload?.exhibitorProductId,
        },
      };
      let resp = await PostCallApi(sData);
      if (resp?.status === 200) {
        if (resp?.data.payload) {
          nav(-1);
          //remove er
          // let v1 = resp1;
          // resp1 = v1;
        }
      }

      ToastSuccess(res?.data.message);
      setProductId(res?.data.payload.exhibitorProductId);
    } else {
      setDerror({ err: res?.data.message });
      ToastError(res?.data?.message);
    }
  };

  const handlePRIm = async (e) => {
    if (e.target.files?.length > 0) {
      // let multifile = e.target.files[0]?.name;
      let base64_namemulti = e.target.files[0];
      if (validateImage(base64_namemulti.name)) {
        setValue(`ProductImage`, base64_namemulti);
        setMainImage(URL.createObjectURL(base64_namemulti));
      } else {
        ToastError(`Invalid file type: ${base64_namemulti.name}`);
      }
    }
  };

  useEffect(() => {
    document.title = "FIG Events | Product";
    // GetCompanyRegistrationList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      GetPrDdetails(id);
    } else {
      AppendArray2({
        fileName: "",
        videoLink: "",
      });
    }
    // eslint-disable-next-line
  }, [id, deleteInfo]);
  // useEffect(() => {
  //   if (companyList?.length > 0) {
  //     if (ecDe) {

  //       setEcDe();
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [companyList, ecDe]);
  const GetPrDdetails = async (id) => {
    let seData = {
      url: `/api/ExhibitorProduct/GetExhibitorProductByExhibitorProductId?exhibitorProductId=${id}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res?.data.success) {
        let sendData = res?.data?.payload[0];
        // setEcDe(sendData);
        setValue("ExhibitorProductId", sendData?.exhibitorProductId);
        setValue("ProductName", sendData?.productName);
        setValue("ShortDescription", sendData?.shortDescription);
        setValue("CategoryName", sendData?.categoryName);
        handleChangeCate(sendData?.categoryName);
        // setValue("SubCategory1Name", sendData?.subCategory1Name);
        // setValue("SubCategory2Name", sendData?.subCategory2Name);
        setValue("Manufacturer", sendData?.manufacturer);
        setValue("BrandName", sendData?.brandName);
        // setValue("Vendor", sendData?.vendor);
        setValue("GTIN", sendData?.gtin);
        // setValue("UPC", sendData?.upc);
        setUpc(sendData?.upc);
        setIngred(sendData?.ingredients);
        setValue("CompanyId", sendData?.companyId);
        setCompanyId(sendData?.companyId);
        // setValue("StorageTypeName", sendData?.storageTypeName);
        // setValue("Ingredients", sendData?.ingredients);
        // setValue(
        //   "SubscriptionType",
        //   sendData?.subscriptionType ? sendData?.subscriptionType : "Bid"
        // );
        if (sendData?.productVideoLink?.length > 0) {
          setValue(
            "ProductVideoUrl",
            sendData?.productVideoLink?.map((x) => {
              return {
                fileName: x?.fileName,
                videoLink: x?.videoUrl,
              };
            })
          );
        } else {
          AppendArray2({
            fileName: "",
            videoLink: "",
          });
        }
        setMainImage(sendData?.productImage ? sendData?.productImage : "");
        setDocsFile(
          sendData?.documents
            ? sendData?.documents
                ?.filter((x) => x !== null)
                ?.map((d) => {
                  return {
                    ...d,
                    documentPath: d?.documentPath,
                  };
                })
            : []
        );
        setMultiImages(
          sendData?.multipleImages
            ? sendData?.multipleImages
                ?.filter((x) => x !== null)
                ?.map((x) => {
                  return {
                    ...x,
                    imagePath: x?.imagePath,
                  };
                })
            : []
        );
      }
    }
  };
  const handleOpen = (d) => {
    setMatD(d);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setMatD();
  };

  const deleteDocument = async () => {
    if (matD?.documentId) {
      let seData = {
        url: `/api/ExhibitorProduct/DeleteExhibitorProductDocumentsById?ExhibitorProductDocumentsId=${matD?.documentId}`,
        body:{},
      };
      //let res = await DeleteCallApi(seData);
      let res = await PostCallApi(seData);
      handleClose();
      if (res?.status === 200) {
        if (res?.data.success) {
          let narOld = docsFile?.filter(
            (x) => x?.documentId !== matD?.documentId
          );
          setDocsFile(narOld);
        }
      }
    }
  };

  const handleMultiImg = (e) => {
    if (e.target.files?.length > 0) {
      if (e.target.files?.length > 5) {
        setValue(`MultipleImage`, "");
        setValue(`multiImg`, "");
      } else if (e.target.files?.length > 0) {
        let multifile = [];
        let base64_namemulti = [];
        for (var i = 0; i < e.target.files?.length; i++) {
          let multi = e.target.files[i];
          if (validateImage(multi.name)) {
            multifile.push(URL.createObjectURL(multi));
            base64_namemulti.push(multi);
          } else {
            ToastError(`Invalid file type: ${multi.name}`);
          }
        }
        setValue(`MultipleImage`, base64_namemulti);
        // setValue(`multiImg`, multifile);
        multifile = multifile?.map((x, i) => {
          return {
            id: i + 1,
            val: "new",
            file: base64_namemulti[i],
            imagePath: x,
          };
        });
        setMultiImages((old) => [...old, ...multifile]);
        e.target.files = null;
      }
    }
  };
  // const handleMainDelete = (img, val) => {
  //   if (img) {
  //     if (val === 1) {
  //       setMainImage();
  //     }
  //   }
  // };
  const handleDoCMultiFile = async (e) => {
    if (e.target.files.length) {
      //   setImgName(e.target.files[0]);
      if (e.target.files.length > 5) {
        setValue(`Documents`, "");
        setValue(`SessionDoc1`, "");
      } else if (e.target.files?.length > 0) {
        let multifile = [];
        let base64_namemulti = [];
        for (var i = 0; i < e.target.files?.length; i++) {
          let multi = e.target.files[i];
          multifile.push(multi?.name);
          base64_namemulti.push(multi);
        }
        multifile = multifile?.map((x, i) => {
          return {
            id: i + 1,
            val: "new",
            file: base64_namemulti[i],
            fileName: x,
          };
        });
        setValue(`Documents`, base64_namemulti);
        // setValue(`SessionDoc1`, multifile);
        setDisplayDoc((old) => [...old, ...multifile]);
      }
    }
  };
  const handleDocsDelete = (mat, no) => {
    setDisplayDoc(displayDoc?.filter((x) => x?.id !== no));
  };

  const handleMultiDelete = (value, id) => {
    setMultiImages(multiImages?.filter((d) => d?.id !== id));
  };

  useEffect(() => {
    if (id) {
      getBnData(id);

      setValue("exhibitorProductId", id);
    } else {
      let bn = [
        {
          benefitTitle: "",
          benefitDescription: "",
        },
      ];
      setValue("benefits", bn);
      setValue("exhibitorProductId", productId);
    }
    // eslint-disable-next-line
  }, [id]);
  const getBnData = async (id) => {
    let seData = {
      url: `/api/ExhibitorProduct/GetProductDetailsByExhibitorProductId?exhibitorProductId=${id}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res?.data.success) {
        let prD1 = res?.data.payload;
        setValue("subscriptionType", prD1?.subscriptionType);
        let prD = res?.data.payload?.benefits;
        if (prD?.length > 0) {
          setValue("benefits", prD);
        } else {
          let bn = [
            {
              benefitTitle: "",
              benefitDescription: "",
            },
          ];
          setValue("benefits", bn);
        }
      }
    }
  };

  const handleDelete = async (data, index) => {
    if (data?.benefitId) {
      let seData = {
        url: `/api/ExhibitorProduct/DeleteProductBenefitsById?benefitId=${data?.benefitId}&deletedBy=${LogDetail?.userId}`,
        body:{},
      };
      //let res = await DeleteCallApi(seData);
      let res = await PostCallApi(seData);
      if (res?.status === 200) {
        if (res?.data.success) {
          getBnData(id);
        }
      }
    } else {
      remove(index);
    }
  };
  const ProductCategoryOption = [
    { value: "Consulting", label: "Consulting" },
    { value: "Equipment", label: "Equipment" },
    { value: "Food Products", label: "Food Products" },
    { value: "Other", label: "Other" },
    { value: "Packaging", label: "Packaging" },
    { value: "Small Wares", label: "Small Wares" },
    { value: "Software/Technology", label: "Software/Technology" },
    { value: "Uniforms", label: "Uniforms" },
  ];
  const handleChangeCate = (val) => {
    setValue("CategoryName", val);
    setCategory(val);
  };
  const handleChangeCompany = (val) => {
    setValue("CompanyId", val);
    setCompanyId(val);
  };

  return (
    <>
      <section className="Ex_prouct_Create">
        <div className="main_wrapper">
          <form onSubmit={handleSubmit(onSubmit)} className="form_box">
            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title mb-3">
                          {id ? "Edit" : "Create"} Product
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Product Name
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Product Name"
                            {...register("ProductName", {
                              required: "This field is required",
                            })}
                          />
                          {errors.ProductName && (
                            <span role="alert" className="error_text">
                              {errors.ProductName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <IndustryDrop
                          isMulti={false}
                          selectedIndustry={[companyId]}
                          handleChangeIndustry={(e) =>
                            handleChangeCompany(e?.value)
                          }
                        />

                        <input
                          type="text"
                          className="form-select d-none"
                          {...register("CompanyId", {
                            required: "This field is required",
                          })}
                        />

                        {errors.CompanyId && (
                          <span role="alert" className="error_text">
                            {errors.CompanyId.message}
                          </span>
                        )}
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Subcription Type</label>
                          <select
                            type="text"
                            className="form-select"
                            {...register("SubscriptionType", {
                              required: "This field is required",
                            })}
                          >
                            <option value="Bid">Bid</option>
                            <option value="Price">Price</option>
                          </select>
                          {errors.SubscriptionType && (
                            <span role="alert" className="error_text">
                              {errors.SubscriptionType.message}
                            </span>
                          )}
                        </div>
                      </div> */}
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Category</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Category"
                            {...register("CategoryName", {
                              // required: "This field is required",
                            })}
                          />
                          {errors.CategoryName && (
                            <span role="alert" className="error_text">
                              {errors.CategoryName.message}
                            </span>
                          )}
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Select Product Category
                            {/* <span className="red-color"> *</span> */}
                          </label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={ProductCategoryOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Category"
                            filterOption={customFilter}
                            value={ProductCategoryOption?.filter(
                              (obj) => obj.value === category
                            )}
                            onChange={(e) => handleChangeCate(e?.value)}
                          />
                          <select
                            type="text"
                            className="form-select d-none"
                            {...register("CategoryName", {
                              onChange: (e) =>
                                handleChangeCate(e?.target?.value),
                            })}
                          >
                            <option value="Food Products">Food Products</option>
                            <option value="Equipment">Equipment</option>
                            <option value="Small Wares">Small Wares</option>
                            <option value="Uniforms">Uniforms</option>
                            <option value="Software/Technology">
                              {" "}
                              Software/Technology{" "}
                            </option>
                            <option value="Consulting">Consulting</option>
                            <option value="Packaging">Packaging</option>
                            <option value="Other">Other</option>
                          </select>
                          {errors.CategoryName && (
                            <span role="alert" className="error_text">
                              {errors.CategoryName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Sub Category One</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Sub Category One"
                            {...register("SubCategory1Name", {
                              // required: "This field is required",
                            })}
                          />
                          {errors.SubCategory1Name && (
                            <span role="alert" className="error_text">
                              {errors.SubCategory1Name.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Sub Category Two</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Sub Category Two"
                            {...register("SubCategory2Name", {
                              // required: "This field is required",
                            })}
                          />
                          {errors.SubCategory2Name && (
                            <span role="alert" className="error_text">
                              {errors.SubCategory2Name.message}
                            </span>
                          )}
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Manufacturer</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Manufacturer"
                            {...register("Manufacturer", {
                              // required: "This field is required",
                            })}
                          />
                          {errors.Manufacturer && (
                            <span role="alert" className="error_text">
                              {errors.Manufacturer.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">BrandName</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter BrandName"
                            {...register("BrandName", {
                              // required: "This field is required",
                            })}
                          />
                          {errors.BrandName && (
                            <span role="alert" className="error_text">
                              {errors.BrandName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Vendor</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Vendor"
                            {...register("Vendor", {
                              // required: "This field is required",
                            })}
                          />
                          {errors.Vendor && (
                            <span role="alert" className="error_text">
                              {errors.Vendor.message}
                            </span>
                          )}
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">GTIN</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter GTIN"
                            {...register("GTIN", {
                              // required: "This field is required",
                            })}
                          />
                          {errors.GTIN && (
                            <span role="alert" className="error_text">
                              {errors.GTIN.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {category === "Food Products" ? (
                        <>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label">UPC</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter UPC"
                                value={upc}
                                onChange={(e) => setUpc(e?.target?.value)}
                                // {...register("UPC", {
                                //   // required: "This field is required",
                                // })}
                              />
                              {/* {errors.UPC && (
                                <span role="alert" className="error_text">
                                  {errors.UPC.message}
                                </span>
                              )} */}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-label">Ingredients</label>
                              <textarea
                                rows="3"
                                type="text"
                                className="form-control"
                                placeholder="Enter Ingredients"
                                value={ingred}
                                onChange={(e) => setIngred(e?.target?.value)}
                                // {...register("Ingredients", {
                                //   // required: "This field is required",
                                // })}
                              />
                              {/* {errors.Ingredients && (
                                <span role="alert" className="error_text">
                                  {errors.Ingredients.message}
                                </span>
                              )} */}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Storage Type</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Storage Type"
                            {...register("StorageTypeName", {
                              // required: "This field is required",
                            })}
                          />
                          {errors.StorageTypeName && (
                            <span role="alert" className="error_text">
                              {errors.StorageTypeName.message}
                            </span>
                          )}
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label"> Description</label>
                          <textarea
                            rows="5"
                            type="text"
                            className="form-control"
                            placeholder="Enter  Description"
                            {...register("ShortDescription", {
                              // required: "This field is required",
                              maxLength: {
                                value: 500,
                                message: "You can entr max 500 characters",
                              },
                            })}
                          />
                          {errors.ShortDescription && (
                            <span role="alert" className="error_text">
                              {errors.ShortDescription.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <h5>Benefit</h5>
                      </div>
                      {fields?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="col-md-9 col-xl-8">
                              <div className="form-group">
                                <label className="form-label">
                                  Title
                                  <span className="red-color"> *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Title"
                                  {...register(
                                    `benefits.${index}.benefitTitle`,
                                    {
                                      required: "This field is required",
                                    }
                                  )}
                                />
                                {errors.benefits?.[index]?.benefitTitle && (
                                  <span role="alert" className="error_text">
                                    {
                                      errors.benefits?.[index]?.benefitTitle
                                        .message
                                    }
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3 col-xl-4 my-auto">
                              {index === 0 ? (
                                <Button
                                  className="common_btn"
                                  onClick={() =>
                                    append({
                                      benefitTitle: "",
                                      benefitDescription: "",
                                    })
                                  }
                                >
                                  Add
                                </Button>
                              ) : (
                                <Button
                                  className="delet_button"
                                  onClick={() => handleDelete(item, index)}
                                >
                                  Delete
                                </Button>
                              )}
                            </div>
                            <div className="col-md-12 ">
                              <div className="form-group">
                                <label className="form-label">
                                  {" "}
                                  Description
                                  <span className="red-color"> *</span>
                                </label>
                                <textarea
                                  rows="3"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter  Description"
                                  {...register(
                                    `benefits.${index}.benefitDescription`,
                                    {
                                      required: "This field is required",
                                      maxLength: {
                                        value: 500,
                                        message:
                                          "You can entr max 500 characters",
                                      },
                                    }
                                  )}
                                />
                                {errors.benefits?.[index]
                                  ?.benefitDescription && (
                                  <span role="alert" className="error_text">
                                    {
                                      errors.benefits?.[index]
                                        ?.benefitDescription.message
                                    }
                                  </span>
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                      <div className="col-md-12 text-end">
                        <div className="form-group">
                          <Button
                            className="common_btn"
                            type="submit"
                            disabled={openCircle}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : id ? (
                              "Update"
                            ) : (
                              "Create"
                            )}
                          </Button>
                          <Button
                            onClick={() => nav(-1)}
                            className="back_button ms-2"
                          >
                            {" "}
                            Back
                          </Button>
                          {derror?.err && (
                            <span role="alert" className="d-block error_text">
                              {derror.err}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Product Main Image</label>
                        <IconButton
                          component="label"
                          className="d-flex gap-3 upload btn_sec m-0"
                        >
                          <div className="upload_icon">
                            <BiUpload />
                          </div>
                          <span style={{ fontSize: "20px" }}>
                            Upload Images
                          </span>
                          <input
                            id="dishImg"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            hidden
                            onChange={(e) => handlePRIm(e)}
                          />
                        </IconButton>
                        {/* crop code end */}
                        {errors.PrIm && (
                          <span role="alert" className="error_text">
                            {errors.PrIm.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <img src={mainImage} alt="images" /> */}
                    {mainImage?.length > 0 ? (
                      <div className="mat_box col-md-12">
                        {mainImage?.length > 0 ? (
                          <label className="form-label">Image : </label>
                        ) : (
                          ""
                        )}
                        <div className="row">
                          <div className="col-md-3">
                            <div className="mat_box_main justify-content-center mb-3">
                              {/* <div className="num me-3">1</div> */}
                              <div className="img_box">
                                <img
                                  src={mainImage}
                                  alt=""
                                  className="img-fluid"
                                  style={{ height: "100px" }}
                                />
                              </div>
                              {/* <button
                              className="btn_dlt "
                              type="button"
                              onClick={() => handleMainDelete(mainImage, 1)}
                            >
                              <VscTrash className="icon" />
                            </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Product Multiple Image
                        </label>
                        {/* <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="No file choose"
                          {...register(`multiImg`)}
                        /> */}
                        <IconButton
                          component="label"
                          className="d-flex gap-3 upload btn_sec m-0"
                        >
                          <div className="upload_icon">
                            <BiUpload />
                          </div>
                          <span style={{ fontSize: "20px" }}>
                            Upload Images
                          </span>
                          <input
                            id="upload_img"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            hidden
                            multiple
                            max={5}
                            onChange={(e) => handleMultiImg(e)}
                          />
                        </IconButton>
                      </div>
                    </div>
                    <div className="mat_box col-md-12">
                      {multiImages?.length > 0 ? (
                        <label className="form-label">Image : </label>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        {multiImages
                          ?.filter((m) => m.val !== "new")
                          ?.map((im, no) => (
                            <div className="col-md-3" key={no}>
                              <div className="mat_box_main mb-3">
                                <div className="img_box">
                                  <img
                                    src={im?.imagePath ? im?.imagePath : ""}
                                    alt=""
                                    className="img-fluid"
                                    style={{ height: "100px", width: "100px" }}
                                  />
                                </div>
                                <DeletePopup
                                  title={"Delete Image"}
                                  text={im?.fileName}
                                  mtd={"post"}
                                  url={`/api/ExhibitorProduct/DeleteExhibitorProductImagesById?exhibitorProductImagesId=${im?.imagesId}`}
                                ></DeletePopup>
                              </div>
                            </div>
                          ))}
                        {multiImages
                          ?.filter((m) => m.val === "new")
                          ?.map((im, no) => (
                            <div className="col-md-3" key={no}>
                              <div className="mat_box_main mb-3">
                                <div className="img_box">
                                  <img
                                    src={im?.imagePath ? im?.imagePath : ""}
                                    alt=""
                                    className="img-fluid"
                                    style={{ height: "100px", width: "100px" }}
                                  />
                                </div>
                                <button
                                  className="btn_dlt"
                                  type="button"
                                  onClick={() => handleMultiDelete(im, im.id)}
                                >
                                  <VscTrash className="icon" />
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Documents (Add Multiple Documents)
                        </label>
                        {/* <div className="d-flex btn_sec"> */}
                        {/* <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="No file choose"
                          {...register(`SessionDoc1`, { 
                            // required: {
                            //   value: true,
                            //   message: "This field is required",
                            // },
                            // onChange: (e) => handleFile(e),
                          {/* })}
                        /> */}
                        <IconButton
                          component="label"
                          className="d-flex gap-3 upload btn_sec m-0"
                        >
                          <div className="upload_icon">
                            <BiUpload />
                          </div>
                          <span style={{ fontSize: "20px" }}>
                            Upload Documents
                          </span>
                          <input
                            id="upload"
                            type="file"
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            multiple
                            max={5}
                            className="form-control"
                            hidden
                            onChange={(e) => handleDoCMultiFile(e)}
                          />
                        </IconButton>
                        {/* </div> */}

                        {/* crop code end */}
                        {errors.SessionDoc1 && (
                          <span role="alert" className="error_text">
                            {errors.SessionDoc1.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mat_box col-md-12">
                      {docsFile?.length > 0 ? "Document :" : ""}
                      <div className="row">
                        {docsFile?.map((mat, no) => (
                          <div className="col-md-6" key={no}>
                            <div className="mat_box_main mb-3">
                              {/* <div className="num me-3">{no + 1}</div> */}
                              <div className="text_data">
                                {mat?.fileName ? mat?.fileName : "Material"}
                              </div>
                              <Link
                                to={`${Config.API_HOST_URL_live}/${mat?.documentPath}`}
                                target="_blank"
                                className="btn_dlt1 me-2"
                              >
                                <HiOutlineEye className="edit" />
                              </Link>
                              <button
                                className="btn_dlt1"
                                type="button"
                                onClick={() => handleOpen(mat)}
                              >
                                <VscTrash className="icon" />
                              </button>
                            </div>
                          </div>
                        ))}
                        {displayDoc?.map((mat, no) => (
                          <div className="col-md-6" key={no}>
                            <div className="mat_box_main mb-3">
                              <div className="num me-3">{no + 1}</div>
                              <div className="text_data">
                                {mat?.fileName ? mat?.fileName : "Material"}
                              </div>

                              <button
                                className="btn_dlt1"
                                type="button"
                                onClick={() => handleDocsDelete(mat, mat.id)}
                              >
                                <VscTrash className="icon" />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-10 col-md-6 my-auto mb-3"></div>

                      {fieldArray2?.map((item, index) => (
                        <React.Fragment key={index}>
                          <div className="col-md-8 ">
                            <div className="form-group">
                              <label className="form-label">Video Title</label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Title"
                                {...register(
                                  `ProductVideoUrl.${index}.fileName`,
                                  {
                                    // required: "this field is required",
                                    pattern: {
                                      value: /^[^\s]+(\s+[^\s]+)*$/,
                                      message:
                                        "Starting and Ending Space not allowed",
                                    },
                                    maxLength: {
                                      value: 250,
                                      message: "Max 250 characters",
                                    },
                                  }
                                )}
                              />
                              {errors.ProductVideoUrl?.[index]?.fileName && (
                                <span role="alert" className="error_text">
                                  {
                                    errors.ProductVideoUrl?.[index]?.fileName
                                      .message
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                          <div className=" col-md-4 mt-auto ">
                            <div className="form-group">
                              {index === 0 ? (
                                <Button
                                  className="common_btn"
                                  onClick={() =>
                                    AppendArray2({
                                      fileName: "",
                                      videoLink: "",
                                    })
                                  }
                                >
                                  {/* <VscAdd className="me-2" /> */}
                                  Add
                                </Button>
                              ) : (
                                <Button
                                  className="delet_Icon_button"
                                  onClick={() => RemoveArray2(index)}
                                >
                                  {" "}
                                  Delete
                                  {/* <MdDelete fontSize={24} /> */}
                                </Button>
                              )}
                            </div>
                          </div>

                          <div className={` mb-3 col-md-12`}>
                            <div className="form-group">
                              <label className="form-label">Video Url</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter URL"
                                {...register(
                                  `ProductVideoUrl.${index}.videoLink`,
                                  {
                                    // required: "this field is required",
                                    pattern: {
                                      value:
                                        // /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                        /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                                      message: "Plese Enter Valid Url",
                                    },
                                  }
                                )}
                              />
                              {errors.ProductVideoUrl?.[index]?.videoLink && (
                                <span role="alert" className="error_text">
                                  {
                                    errors.ProductVideoUrl?.[index]?.videoLink
                                      .message
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      {open && (
        <>
          <Modal
            sx={{ zIndex: 9 }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <h6>Delete Class Document</h6>
                <div className="popup_delete_detail">
                  <p className="delete_text">
                    Are you sure you want to Delete
                    <span> {matD?.fileName}?</span>
                  </p>
                  {derror?.err && (
                    <span role="alert" className="d-block error_text">
                      {derror.err}
                    </span>
                  )}
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={deleteDocument}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default ProductCreate;
