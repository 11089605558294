import React, { Suspense, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  Tooltip,
} from "@mui/material";
import AttendeeScheduleTable from "./AttendeeScheduleTable";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import ImgPopUp from "../../Components/ImgPopUp";
import { Config } from "../../../Utils/Config";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { LuCalendarMinus, LuCalendarPlus } from "react-icons/lu";
import { Link } from "react-router-dom";
import TableHeader from "../../../TableHeader.json";
import { ToastError, ToastSuccess } from "../../Components/Toast";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: "50%",
  width: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: 0,
};

function AttendeeSchedule() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [conferenceId, setConferenceId] = useState("");
  const [classesEventDetail] = useState();
  // const [classesEventDetail, setClassesEventDetail] = useState();
  const [open, setOpen] = useState(false);
  const qrRef = useRef();
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  useEffect(() => {
    document.title = "FIG Events | Attendee Schedule";
    GetSessionEventList();
    // eslint-disable-next-line
  }, []);

  const GetSessionEventList = async (conId) => {
    setOpenCircle(true);
    setDataOfTable([]);
    setDataOfTable1([]);
    let res = await GetCallApi({
      url: `/api/AttendeeSchedule/GetAttendeeConferenceScheduleList?userId=${LogDetails?.userId}`,
    });
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((x, i) => {
          return {
            ...x,
            value: x?.conferenceId,
            label: x?.conferenceName,
            id: i + 1,
          };
        });
        setDataOfTable(comList);
        if (conId) {
          setConferenceId(conId);
          let conData = comList?.filter((x) => x?.value === conId)[0];

          let sessionList = conData?.sessionDetails?.map((x) => {
            let stime = x?.classStartTime
              ? TimeTo12HourFormat(x?.classStartTime)
              : "";
            let etime = x?.sessionEndTime
              ? TimeTo12HourFormat(x?.sessionEndTime)
              : "";
            let sDate = x?.sessionStartDate
              ? new Date(x?.sessionStartDate).toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "";
            // let url =
            //   "/conference/" +
            //   conData?.conferenceId +
            //   "/Session/" +
            //   x?.classId +
            //   "/userId/" +
            //   LogDetails?.userId;
            return {
              ...x,
              name: x?.sessionName,
              keyArea: x?.keyarea
                ?.map(
                  (y) => y?.courseCategoryCode + "-" + y?.courseCategoryName
                )
                ?.join(", "),
              learningObjective: x?.learningObjectives
                ?.map(
                  (y) => y?.courseCategoryCode + "-" + y?.courseCategoryName
                )
                ?.join(", "),
              trainingTopics: x?.trainingTopics
                ?.map(
                  (y) => y?.courseCategoryCode + "-" + y?.courseCategoryName
                )
                ?.join(", "),
              timeStartEnd: sDate + " " + stime + " - " + etime,
              type: "Session",
              image: x.coverImages ? (
                <ImgPopUp
                  img={Config.API_HOST_URL_live + x?.coverImages}
                  addClass={"h_fix"}
                  hide_icon={true}
                />
              ) : (
                ""
              ),
              Action: (
                //  x?.isRegistered ?
                <>
                  {/* <Button
                    className="download_btn me-3"
                    onClick={() =>
                      handleDownload({
                        ...x,
                        name: x?.className,
                        url: url,
                        conferenceId: conData?.conferenceId,
                      })
                    }
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      background:
                        "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <div className="text">
                      <img src={qrcodeIcon} alt="QR Code" />
                      <span className="ms-2">Download QR</span>
                    </div>
                  </Button> */}
                  {x?.isAddedToSchedule ? (
                    <Tooltip title="Remove From Schedule">
                      <Link
                        className="btn_edit remove"
                        onClick={() =>
                          handleRemoveSchedule(
                            x?.attendeeScheduleId,
                            conData?.conferenceId
                          )
                        }
                      >
                        <LuCalendarMinus />
                      </Link>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add To Schedule">
                      <Link
                        className="btn_edit add"
                        onClick={() =>
                          handleAddSchedule({
                            conferenceDetail: conData,
                            sendId: x?.sessionId,
                            type: "Session",
                          })
                        }
                      >
                        <LuCalendarPlus />
                      </Link>
                    </Tooltip>
                  )}
                </>
                // ) : (
                //   ""
              ),
            };
          });
          let eventList = conData?.eventDetails?.map((x) => {
            let stime = x?.eventStartTime
              ? TimeTo12HourFormat(x?.eventStartTime)
              : "";
            let etime = x?.eventEndTime
              ? TimeTo12HourFormat(x?.eventEndTime)
              : "";
            let sDate = x?.eventStartDate
              ? new Date(x?.eventStartDate).toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "";
            let eDate = x?.eventEndDate
              ? new Date(x?.eventEndDate).toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "";
            // let url =
            //   "/conference/" +
            //   conData?.conferenceId +
            //   "/Event/" +
            //   x?.eventId +
            //   "/userId/" +
            //   LogDetails?.userId;
            return {
              ...x,
              name: x?.eventName,
              timeStartEnd: sDate + " " + stime + " - " + eDate + " " + etime,
              fee: x?.eventPrice,
              type: "Event",
              image: x.displayImage ? (
                <ImgPopUp
                  img={Config.API_HOST_URL_live + x?.displayImage}
                  addClass={"h_fix"}
                  hide_icon={true}
                />
              ) : (
                ""
              ),
              Action: (
                // x?.isRegistered ? (
                <>
                  {/* <Button
                    className="download_btn me-3"
                    onClick={() =>
                      handleDownload({
                        ...x,
                        name: x?.eventName,
                        url: url,
                        conferenceId: conData?.conferenceId,
                      })
                    }
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      background:
                        "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <div className="text">
                      <img src={qrcodeIcon} alt="QR Code" />
                      <span className="ms-2">Download QR</span>
                    </div>
                  </Button> */}
                  {x?.isAddedToSchedule ? (
                    <Tooltip title="Remove From Schedule">
                      <Link
                        className="btn_edit remove"
                        onClick={() =>
                          handleRemoveSchedule(
                            x?.attendeeScheduleId,
                            conData?.conferenceId
                          )
                        }
                      >
                        <LuCalendarMinus />
                      </Link>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add To Schedule">
                      <Link
                        className="btn_edit add"
                        onClick={() =>
                          handleAddSchedule({
                            conferenceDetail: conData,
                            sendId: x?.eventId,
                            type: "Event",
                            eventAttendee: x?.eventAttendee,
                          })
                        }
                      >
                        <LuCalendarPlus />
                      </Link>
                    </Tooltip>
                  )}
                </>
              ),
              // ) : x?.eventPrice === 0 ? (
              //   x?.isAddedToSchedule ? (
              //     <Button
              //       className="btn_edit"
              //       onClick={() =>
              //         handleRemoveSchedule(
              //           x?.attendeeScheduleId,
              //           conData?.conferenceId
              //         )
              //       }
              //     >
              //       Remove from Schedule
              //     </Button>
              //   ) : (
              //     <Button
              //       className="btn_edit"
              //       onClick={() =>
              //         handleAddSchedule({
              //           conferenceDetail: conData,
              //           sendId: x?.eventId,
              //           eventAttendee: x?.eventAttendee,
              //           type: "Event",
              //         })
              //       }
              //     >
              //       Add to Schedule
              //     </Button>
              //   )
              // ) : conData?.registerUserType === "Group" ? (
              //   <div
              //     style={{
              //       maxWidth: "200px",
              //       whiteSpace: "break-spaces",
              //       color: "red",
              //     }}
              //   >
              //     Your registration done by group that&apos;s why you are not
              //     able to add paid events.
              //   </div>
              // ) : (
              //   <Link
              //     className="delet_button"
              //     to={`/pay-to-schedule/${conData?.conferenceId}`}
              //   >
              //     Pay to Schedule
              //   </Link>
              // ),
            };
          });
          let mainArrray =
            sessionList?.length > 0 && eventList?.length > 0
              ? [...sessionList, ...eventList]?.map((x, i) => {
                  return { ...x, id: i + 1 };
                })
              : sessionList?.length > 0
              ? sessionList?.map((x, i) => {
                  return { ...x, id: i + 1 };
                })
              : eventList?.length > 0
              ? eventList?.map((x, i) => {
                  return { ...x, id: i + 1 };
                })
              : [];
          setDataOfTable1(mainArrray);
        } else {
          setConferenceId(comList[0]?.conferenceId);
          let sessionList = comList[0]?.sessionDetails?.map((x) => {
            let stime = x?.classStartTime
              ? TimeTo12HourFormat(x?.classStartTime)
              : "";
            let etime = x?.sessionEndTime
              ? TimeTo12HourFormat(x?.sessionEndTime)
              : "";
            let sDate = x?.sessionStartDate
              ? new Date(x?.sessionStartDate).toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "";
            // let url =
            //   "/conference/" +
            //   comList[0]?.conferenceId +
            //   "/Session/" +
            //   x?.classId +
            //   "/userId/" +
            //   LogDetails?.userId;
            return {
              ...x,
              name: x?.sessionName,
              keyArea: x?.keyarea
                ?.map(
                  (y) => y?.courseCategoryCode + "-" + y?.courseCategoryName
                )
                ?.join(", "),
              learningObjective: x?.learningObjectives
                ?.map(
                  (y) => y?.courseCategoryCode + "-" + y?.courseCategoryName
                )
                ?.join(", "),
              trainingTopics: x?.trainingTopics
                ?.map(
                  (y) => y?.courseCategoryCode + "-" + y?.courseCategoryName
                )
                ?.join(", "),
              timeStartEnd: sDate + " " + stime + " - " + etime,
              type: "Session",
              image: x.coverImages ? (
                <ImgPopUp
                  img={Config.API_HOST_URL_live + x?.coverImages}
                  addClass={"h_fix"}
                  hide_icon={true}
                />
              ) : (
                ""
              ),
              Action: (
                //  x?.isRegistered ?
                <>
                  {/* <Button
                    className="download_btn me-3"
                    onClick={() =>
                      handleDownload({
                        ...x,
                        name: x?.className,
                        url: url,
                        conferenceId: comList[0]?.conferenceId,
                      })
                    }
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      background:
                        "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <div className="text">
                      <img src={qrcodeIcon} alt="QR Code" />
                      <span className="ms-2">Download QR</span>
                    </div>
                  </Button> */}
                  {x?.isAddedToSchedule ? (
                    <Tooltip title="Remove From Schedule">
                      <Link
                        className="btn_edit remove"
                        onClick={() =>
                          handleRemoveSchedule(
                            x?.attendeeScheduleId,
                            comList[0]?.conferenceId
                          )
                        }
                      >
                        <LuCalendarMinus />
                      </Link>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add To Schedule">
                      <Link
                        className="btn_edit add"
                        onClick={() =>
                          handleAddSchedule({
                            conferenceDetail: comList[0],
                            sendId: x?.sessionId,
                            type: "Session",
                          })
                        }
                      >
                        <LuCalendarPlus />
                      </Link>
                    </Tooltip>
                  )}
                </>
                // ) : (
                //   ""
              ),
            };
          });
          let eventList = comList[0]?.eventDetails?.map((x) => {
            let stime = x?.eventStartTime
              ? TimeTo12HourFormat(x?.eventStartTime)
              : "";
            let etime = x?.eventEndTime
              ? TimeTo12HourFormat(x?.eventEndTime)
              : "";
            let sDate = x?.eventStartDate
              ? new Date(x?.eventStartDate).toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "";
            let eDate = x?.eventEndDate
              ? new Date(x?.eventEndDate).toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "";
            // let url =
            //   "/conference/" +
            //   comList[0]?.conferenceId +
            //   "/Event/" +
            //   x?.eventId +
            //   "/userId/" +
            //   LogDetails?.userId;
            return {
              ...x,
              name: x?.eventName,
              timeStartEnd: sDate + " " + stime + " - " + eDate + " " + etime,
              fee: x?.eventPrice,
              type: "Event",
              image: x.displayImage ? (
                <ImgPopUp
                  img={Config.API_HOST_URL_live + x?.displayImage}
                  addClass={"h_fix"}
                  hide_icon={true}
                />
              ) : (
                ""
              ),
              Action: (
                // x?.isRegistered ? (
                <>
                  {/* <Button
                    className="download_btn me-3"
                    onClick={() =>
                      handleDownload({
                        ...x,
                        name: x?.eventName,
                        url: url,
                        conferenceId: comList[0]?.conferenceId,
                      })
                    }
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      background:
                        "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <div className="text">
                      <img src={qrcodeIcon} alt="QR Code" />
                      <span className="ms-2">Download QR</span>
                    </div>
                  </Button> */}
                  {x?.isAddedToSchedule ? (
                    <Tooltip title="Remove From Schedule">
                      <Link
                        className="btn_edit remove"
                        onClick={() =>
                          handleRemoveSchedule(
                            x?.attendeeScheduleId,
                            comList[0]?.conferenceId
                          )
                        }
                      >
                        <LuCalendarMinus />
                      </Link>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add To Schedule">
                      <Link
                        className="btn_edit add"
                        onClick={() =>
                          handleAddSchedule({
                            conferenceDetail: comList[0],
                            sendId: x?.eventId,
                            type: "Event",
                            eventAttendee: x?.eventAttendee,
                          })
                        }
                      >
                        <LuCalendarPlus />
                      </Link>
                    </Tooltip>
                  )}
                </>
              ),
              // ) : x?.eventPrice === 0 ? (
              //   x?.isAddedToSchedule ? (
              //     <Button
              //       className="btn_edit"
              //       onClick={() =>
              //         handleRemoveSchedule(
              //           x?.attendeeScheduleId,
              //           comList[0]?.conferenceId
              //         )
              //       }
              //     >
              //       Remove from Schedule
              //     </Button>
              //   ) : (
              //     <Button
              //       className="btn_edit"
              //       onClick={() =>
              //         handleAddSchedule({
              //           conferenceDetail: comList[0],
              //           sendId: x?.eventId,
              //           eventAttendee: x?.eventAttendee,
              //           type: "Event",
              //         })
              //       }
              //     >
              //       Add to Schedule
              //     </Button>
              //   )
              // ) : comList[0]?.registerUserType === "Group" ? (
              //   <div
              //     style={{
              //       maxWidth: "200px",
              //       whiteSpace: "break-spaces",
              //       color: "red",
              //     }}
              //   >
              //     Your registration done by group that&apos;s why you are not
              //     able to add paid events.
              //   </div>
              // ) : (
              //   <Link
              //     className="delet_button"
              //     to={`/pay-to-schedule/${comList[0]?.conferenceId}`}
              //   >
              //     Pay to Schedule
              //   </Link>
              // ),
            };
          });
          let mainArrray =
            sessionList?.length > 0 && eventList?.length > 0
              ? [...sessionList, ...eventList]?.map((x, i) => {
                  return { ...x, id: i + 1 };
                })
              : sessionList?.length > 0
              ? sessionList?.map((x, i) => {
                  return { ...x, id: i + 1 };
                })
              : eventList?.length > 0
              ? eventList?.map((x, i) => {
                  return { ...x, id: i + 1 };
                })
              : [];
          setDataOfTable1(mainArrray);
        }
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  function TimeTo12HourFormat(time) {
    let [hours, minutes] = time.split(":").map(Number);

    let period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    hours = hours < 10 ? "0" + hours : hours;
    hours = hours ? hours : "00";
    minutes = minutes < 10 ? "0" + minutes : minutes;
    minutes = minutes ? minutes : "00";
    return `${hours}:${minutes} ${period}`;
  }
  const HandleManage = (conId) => {
    setConferenceId(conId);
    if (conId) {
      let conData = dataOfTable?.filter((x) => x?.value === conId)[0];
      let sessionList = conData?.sessionDetails?.map((x) => {
        let stime = x?.classStartTime
          ? TimeTo12HourFormat(x?.classStartTime)
          : "";
        let etime = x?.sessionEndTime ? TimeTo12HourFormat(x?.sessionEndTime) : "";
        let sDate = x?.sessionStartDate
          ? new Date(x?.sessionStartDate).toLocaleDateString("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            })
          : "";
        // let url =
        //   "/conference/" +
        //   conData?.conferenceId +
        //   "/Session/" +
        //   x?.classId +
        //   "/userId/" +
        //   LogDetails?.userId;
        return {
          ...x,
          name: x?.sessionName,
          keyArea: x?.keyarea
            ?.map((y) => y?.courseCategoryCode + "-" + y?.courseCategoryName)
            .join(", "),
          learningObjective: x?.learningObjectives
            ?.map((y) => y?.courseCategoryCode + "-" + y?.courseCategoryName)
            ?.join(", "),
          trainingTopics: x?.trainingTopics
            ?.map((y) => y?.courseCategoryCode + "-" + y?.courseCategoryName)
            ?.join(", "),
          timeStartEnd: sDate + " " + stime + " - " + etime,
          type: "Session",
          image: x.coverImages ? (
            <ImgPopUp
              img={Config.API_HOST_URL_live + x?.coverImages}
              addClass={"h_fix"}
              hide_icon={true}
            />
          ) : (
            ""
          ),
          Action: (
            // x?.isRegistered ?
            <>
              {/* <Button
                className="download_btn me-3"
                onClick={() =>
                  handleDownload({
                    ...x,
                    name: x?.className,
                    url: url,
                    conferenceId: conData?.conferenceId,
                  })
                }
                style={{
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  background:
                    "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                  padding: "5px 15px",
                  borderRadius: "5px",
                  color: "white",
                  textTransform: "none",
                }}
              >
                <div className="text">
                  <img src={qrcodeIcon} alt="QR Code" />
                  <span className="ms-2">Download QR</span>
                </div>
              </Button> */}
              {x?.isAddedToSchedule ? (
                <Tooltip title="Remove From Schedule">
                  <Link
                    className="btn_edit remove"
                    onClick={() =>
                      handleRemoveSchedule(
                        x?.attendeeScheduleId,
                        conData?.conferenceId
                      )
                    }
                  >
                    <LuCalendarMinus />
                  </Link>
                </Tooltip>
              ) : (
                <Tooltip title="Add To Schedule">
                  <Link
                    className="btn_edit add"
                    onClick={() =>
                      handleAddSchedule({
                        conferenceDetail: conData,
                        sendId: x?.sessionId,
                        type: "Session",
                      })
                    }
                  >
                    <LuCalendarPlus />
                  </Link>
                </Tooltip>
              )}
            </>
            // ) : (
            //   ""
          ),
        };
      });
      let eventList = conData?.eventDetails?.map((x) => {
        let stime = x?.eventStartTime
          ? TimeTo12HourFormat(x?.eventStartTime)
          : "";
        let etime = x?.eventEndTime ? TimeTo12HourFormat(x?.eventEndTime) : "";
        let sDate = x?.eventStartDate
          ? new Date(x?.eventStartDate).toLocaleDateString("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            })
          : "";
        let eDate = x?.eventEndDate
          ? new Date(x?.eventEndDate).toLocaleDateString("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            })
          : "";
        // let url =
        //   "/conference/" +
        //   conData?.conferenceId +
        //   "/Event/" +
        //   x?.eventId +
        //   "/userId/" +
        //   LogDetails?.userId;
        return {
          ...x,
          timeStartEnd: sDate + " " + stime + " - " + eDate + " " + etime,
          name: x?.eventName,
          fee: x?.eventPrice,
          type: "Event",
          image: x.displayImage ? (
            <ImgPopUp
              img={Config.API_HOST_URL_live + x?.displayImage}
              addClass={"h_fix"}
              hide_icon={true}
            />
          ) : (
            ""
          ),
          Action: (
            //  x?.isRegistered ? (
            <>
              {/* <Button
                className="download_btn me-3"
                onClick={() =>
                  handleDownload({
                    ...x,
                    name: x?.eventName,
                    url: url,
                    conferenceId: conData?.conferenceId,
                  })
                }
                style={{
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  background:
                    "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                  padding: "5px 15px",
                  borderRadius: "5px",
                  color: "white",
                  textTransform: "none",
                }}
              >
                <div className="text">
                  <img src={qrcodeIcon} alt="QR Code" />
                  <span className="ms-2">Download QR</span>
                </div>
              </Button> */}
              {x?.isAddedToSchedule ? (
                <Tooltip title="Remove From Schedule">
                  <Link
                    className="btn_edit remove"
                    onClick={() =>
                      handleRemoveSchedule(
                        x?.attendeeScheduleId,
                        conData?.conferenceId
                      )
                    }
                  >
                    <LuCalendarMinus />
                  </Link>
                </Tooltip>
              ) : (
                <Tooltip title="Add To Schedule">
                  <Link
                    className="btn_edit add"
                    onClick={() =>
                      handleAddSchedule({
                        conferenceDetail: conData,
                        sendId: x?.eventId,
                        type: "Event",
                        eventAttendee: x?.eventAttendee,
                      })
                    }
                  >
                    <LuCalendarPlus />
                  </Link>
                </Tooltip>
              )}
            </>
          ),
          // ) : x?.eventPrice === 0 ? (
          //   x?.isAddedToSchedule ? (
          //     <Button
          //       className="btn_edit"
          //       onClick={() =>
          //         handleRemoveSchedule(
          //           x?.attendeeScheduleId,
          //           conData?.conferenceId
          //         )
          //       }
          //     >
          //       Remove from Schedule
          //     </Button>
          //   ) : (
          //     <Button
          //       className="btn_edit"
          //       onClick={() =>
          //         handleAddSchedule({
          //           conferenceDetail: conData,
          //           sendId: x?.eventId,
          //           eventAttendee: x?.eventAttendee,
          //           type: "Event",
          //         })
          //       }
          //     >
          //       Add to Schedule
          //     </Button>
          //   )
          // ) : conData?.registerUserType === "Group" ? (
          //   <div
          //     style={{
          //       maxWidth: "200px",
          //       whiteSpace: "break-spaces",
          //       color: "red",
          //     }}
          //   >
          //     Your registration done by group that&apos;s why you are not able
          //     to add paid events.
          //   </div>
          // ) : (
          //   <Link
          //     className="delet_button"
          //     to={`/pay-to-schedule/${conData?.conferenceId}`}
          //   >
          //     Pay to Schedule
          //   </Link>
          // ),
        };
      });
      let mainArrray =
        sessionList?.length > 0 && eventList?.length > 0
          ? [...sessionList, ...eventList]?.map((x, i) => {
              return { ...x, id: i + 1 };
            })
          : sessionList?.length > 0
          ? sessionList?.map((x, i) => {
              return { ...x, id: i + 1 };
            })
          : eventList?.length > 0
          ? eventList?.map((x, i) => {
              return { ...x, id: i + 1 };
            })
          : [];
      setDataOfTable1(mainArrray);
    } else {
      setDataOfTable1([]);
    }
  };

  const handleRemoveSchedule = async (sId, conId) => {
    setOpenCircle(true);
    // let res = await DeleteCallApi({
    //   url: `/api/AttendeeSchedule/DeleteAttendeeSchedule?attendeeScheduleId=${sId}&deletedBy=${LogDetails?.userId}`,
    // });
     let res = await PostCallApi({
      url: `/api/AttendeeSchedule/DeleteAttendeeSchedule?attendeeScheduleId=${sId}&deletedBy=${LogDetails?.userId}`,
      body:{},
    });
    if (res?.status === 200 && res?.data.success) {
      GetSessionEventList(conId);
      ToastSuccess(res?.data.message);
    } else {
      ToastError(res?.data.message);
    }
  };
  const handleAddSchedule = async (data) => {
    setOpenCircle(true);
    let res = await PostCallApi({
      url: `/api/AttendeeSchedule/AddAttendeeSchedule`,
      body: {
        userId: LogDetails?.userId,
        createdBy: LogDetails?.userId,
        conferenceID: data?.conferenceDetail?.conferenceId,
        registerUserType: data?.conferenceDetail?.registerUserType,
        memberManagementId: data?.conferenceDetail?.memberManagementId,
        associationMemberId: data?.conferenceDetail?.associationMemberId,
        sessionOrEventId: data?.sendId,
        AttendeePaymentId: data?.conferenceDetail?.attendeePaymentId,
        SessionOrEventType: data?.type,
        eventAttendee: data?.eventAttendee,
        eventPrice: 0,
      },
    });
    setOpenCircle(false);

    if (res?.status === 200 && res?.data.success) {
      GetSessionEventList(data?.conferenceDetail?.conferenceId);
      ToastSuccess(res?.data.message);
    } else {
      ToastError(res?.data.message);
    }
  };

  const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(qrRef.current);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadQRCode(image, imageFileName);
  };
  const downloadQRCode = async (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setOpen(false);
  };

  // const handleDownload = (c) => {
  //   setClassesEventDetail(c);
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="AttendeeSchedule py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <AttendeeScheduleTable
                  title="Add Schedule"
                  action={true}
                  headCells={TableHeader?.AttendeeScheduleHeader}
                  ListData={dataOfTable1}
                  dropdown={false}
                  addBtn={false}
                  showUserListInTable={true}
                  fileName={"Add Schedule"}
                  exportDataList={false}
                  innerProduct={true}
                  beforeSearchDropdown={true}
                  beforeSearchDropdownValue={conferenceId}
                  beforeSearchDropdownCall={(e) => HandleManage(e)}
                  beforeSearchDropdownPlaceholder={"Select Conference"}
                  beforeSearchDropdownArray={[
                    // { value: "", label: "Select Conefernce" },
                    ...dataOfTable,
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
        {open && (
          <Modal
            sx={{ zIndex: 9 }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="popup_box_add_drag"
          >
            <Box sx={style} className={`main_box `}>
              <div ref={qrRef}>
                <div
                  className=" p-3 w-auto"
                  style={{ color: "#80c242", fontSize: "18px" }}
                >
                  {classesEventDetail?.name}
                </div>
                <hr />
                <div className="col-md-12 d-flex justify-content-center mb-3">
                  <div className="qr_box">
                    <div style={{ padding: "1rem" }} className="qr_image">
                      <QRCode value={classesEventDetail?.url} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-evenly">
                  <Button
                    className="back_button"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn_edit"
                    onClick={(e) => exportAsImage(e, classesEventDetail?.name)}
                  >
                    Download
                  </Button>
                  {/* className="common_btn mx-auto" */}
                </div>
              </div>
              {/* </form> */}
            </Box>
          </Modal>
        )}
      </Suspense>
    </>
  );
}

export default AttendeeSchedule;
