import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { Link, useParams } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../../../Action/Action";
import { useSelector } from "react-redux";

const Togglebutton = lazy(() =>
  import("../../../../../Components/Togglebutton")
);
const NewLIstTable = lazy(() =>
  import("../../../../../Components/NewLIstTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCells = [
  {
    id: "no",
    label: "#",
  },
  {
    id: "productName",
    label: "Product Name",
  },
  {
    id: "promoTitle",
    label: "Discount Title",
  },
  {
    id: "productPromoCode1",
    label: "Discount Code",
  },

  {
    id: "promoType",
    label: "Discount Type",
  },
  {
    id: "promoValue",
    label: "Discount Value",
  },
  {
    id: "minValue",
    label: "Min Value",
  },

  {
    id: "startDate",
    label: "Start Date",
  },
  {
    id: "endDate",
    label: "End Date",
  },
];

function AssociationProductDiscountList() {
  const { prdId } = useParams();
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    GetDiscountList();
    document.title = "FIG Events | Product Discount Code";

    // eslint-disable-next-line
  }, []);

  const GetDiscountList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/ProductPromoCode/GetAllProductPromoCodeList?productId=${prdId}`,
      // url: `/api/PromoCode/GetAllPromoCodeList?associationProductId=${prdId}`,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            no: i + 1,
            ...c,
            ...c.pc,
            startDate: c?.pc?.startDate
              ? new Date(c?.pc?.startDate).toLocaleDateString("en-us", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "",
            endDate: c?.pc?.endDate
              ? new Date(c?.pc?.endDate).toLocaleDateString("en-us", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "",
            Action: (
              <>
                {c?.pc.status && (
                  <Link
                    className="btn_edit me-3 d-flex align-items-center"
                    to={`edit/${c?.pc?.productPromoCodeId}`}
                  >
                    <VscEdit />
                  </Link>
                )}

                <Togglebutton
                  checked={c?.pc.status === true ? true : false}
                  title={"Delete Product Discount Code"}
                  disabled={false}
                  text={c?.pc?.promoTitle}
                  mtd={"post"}
                  url={`/api/ProductPromoCode/DeleteProductPromoCode?productPromoCodeId=${c?.pc?.productPromoCodeId}&deletedBy=${LogDetails?.userId}`}
                  callBack={true}
                  callBackFunction={GetDiscountList}
                />
              </>
            ),
          };
        });
        setDataOfTable1(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <NewLIstTable
                  addressLink={`create`}
                  headCells={headCells}
                  title="Product Discount Code List"
                  action={true}
                  ListData={dataOfTable1}
                  dropdown={false}
                  addBtn={true}
                  as="no"
                />
              </div>
            </div>
            {/* <div className="card mb-3">
              <div className="card-body">
              </div>
            </div> */}
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AssociationProductDiscountList;
