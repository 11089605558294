import React, { Suspense, lazy, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { VscEdit } from "react-icons/vsc";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../Action/ConferenceListApi.jsx";
import { customFilter } from "../../Components/CustomFilterForReactSelect/index.jsx";
import { ToastError, ToastSuccess } from "../../Components/Toast";
import { useForm } from "react-hook-form";
import TableHeader from "../../../TableHeader.json";
import { useSelector } from "react-redux";

const Togglebutton = lazy(() => import("../../Components/Togglebutton"));
const NewLIstTable = lazy(() =>
  import("../../Components/NewLIstTable/index.jsx")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 600 : "90%",
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function ConferenceFeedbackQuestion() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [apData, setApData] = useState();
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [sendUserType, setSendUserType] = useState();
  const [filterPage, setFilterPage] = useState(false);
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Conference Feedback Question";
    getConfList();
    // eslint-disable-next-line
  }, []);

  const { handleSubmit, reset, setValue } = useForm();

  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConList(res);
      // handleConfereceSelect(res[0]?.value);
    }
  };

  const GetQuestionList = async (data) => {
    setOpenCircle(true);
    setDataOfTable([]);
    let res = await GetCallApi({
      url: `/api/ConferenceFeedbackQuestion/GetConferenceFeedbackQuestionList?confernceId=${
        data?.conferenceId ? data?.conferenceId : ""
      }&sendUserType=${data?.sendUserType ? data?.sendUserType : ""}`,
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);

    if (res?.status === 200 && res?.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          groupUserList:
            c?.questionList?.length > 0
              ? c?.questionList?.map((y, index) => {
                  return { ...y, id: index + 1 };
                })
              : "",
          Action: (
            <>
              {c?.isActive && (
                <Link
                  to={`edit/${c.submittedId}`}
                  className="btn_edit me-3 d-flex align-items-center"
                >
                  <VscEdit />
                </Link>
              )}
              <Togglebutton
                checked={c?.isActive === true ? true : false}
                title={"Delete Conference Feedback Question"}
                disabled={false}
                text={c?.conferenceName + " Question"}
                mtd={"post"}
                url={`/api/ConferenceFeedbackQuestion/DeleteConferenceFeedbackQuestionBySubmittedId?submittedId=${c?.submittedId}&deleteBy=${LogDetails?.userId}`}
                callBack={true}
                callBackFunction={GetQuestionList}
              />
              {c?.isActive && (
                <button
                  className="btn_edit ms-3"
                  onClick={() => handleOpenPop(c)}
                >
                  {" "}
                  Send Mail
                </button>
              )}
            </>
          ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const handleOpenPop = (eId) => {
    setApData(eId);
    setOpenPop(true);
  };
  const handleClose = () => {
    setApData();
    setOpenPop(false);
  };
  const HandleRequest = async () => {
    setOpenCircle(true);

    let res = await PostCallApi({
      url: `/api/ConferenceFeedbackQuestion/SendEmailToUserForConferenceFeedbackForm?submittedId=${apData?.submittedId}`,
      body: {},
    });

    setOpenCircle(false);
    if (res?.status === 200 && res?.data.success) {
      handleClose();
      let msg = res?.data.message;
      ToastSuccess(msg);
    }
  };

  const handleConfereceSelect = (e) => {
    setConfId(e);
    setValue("conferenceId", e);
  };

  const sendUserTypeOption = [
    { value: "All", label: "All" },
    { value: "Event Organizer", label: "Event Organizer" },
    { value: "Exhibitor", label: "Exhibitor" },
    { value: "User", label: "User" },
  ];

  const handleSendUserType = (e) => {
    setSendUserType(e);
    setValue("sendUserType", e);
  };

  const handleClearFilter = () => {
    setConfId();
    setSendUserType();
    setDataOfTable([]);
    reset();
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={handleSubmit(GetQuestionList)}>
                      <div className="row" style={{ marginLeft: "20px" }}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Conference</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={conList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Conference"
                              filterOption={customFilter}
                              value={conList?.filter(
                                (obj) => obj.value === confId
                              )}
                              onChange={(e) => handleConfereceSelect(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Send User Type</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={sendUserTypeOption}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Send User Type"
                              filterOption={customFilter}
                              value={sendUserTypeOption?.filter(
                                (obj) => obj.value === sendUserType
                              )}
                              onChange={(e) => handleSendUserType(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-start mt-auto">
                          <div className="form-group">
                            <Button
                              className="common_btn ms-3"
                              type="submit"
                              disabled={openCircle ? true : false}
                            >
                              {openCircle ? (
                                <CircularProgress
                                  color="inherit"
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              className={`back_button ms-3 ${
                                window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                              onClick={() => handleClearFilter()}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    addressLink={`create`}
                    addBtn={true}
                    headCells={TableHeader?.ConfFeedBackQuestionList}
                    title="Conference Feedback Question List"
                    action={true}
                    ListData={dataOfTable}
                    dropdown={false}
                    showUserListInTable={true}
                    innerProduct={true}
                    filterPage={filterPage}
                    subHeadCells={
                      TableHeader?.ConfFeedBackQuestionListSubHeader
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {openPop && (
          <>
            <Modal
              sx={{ zIndex: 9 }}
              open={openPop}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
            >
              <Box sx={style} className="delete_data_box">
                <div className="popup_delete_detail_box">
                  <h6>Conference Feedback Question Send Mail </h6>
                  <div className="popup_delete_detail">
                    <p className="delete_text">
                      Are you sure you want send mail to{" "}
                      <span>
                        {apData?.conferenceName} Conference Feedback Question to{" "}
                        {apData?.sendUserType} ?
                      </span>
                    </p>
                    <Button
                      type="button"
                      className="popup_btn delete"
                      onClick={HandleRequest}
                    >
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                    <Button
                      type="button"
                      className="popup_btn cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </>
        )}
      </Suspense>
    </>
  );
}

export default ConferenceFeedbackQuestion;
