import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";
import ImgPopUp from "../../../Components/ImgPopUp";
import { Config } from "../../../../Utils/Config";
import { Link } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";
import { TbDiscount2 } from "react-icons/tb";

const NotFound = "/assets/images/Image_not_available.jpg";

const DeletePopup = lazy(() =>
  import("../../../Components/DeletePopup/index.jsx")
);
const NewLIstTable = lazy(() =>
  import("../../../Components/NewLIstTable/index.jsx")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCells = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "logo",
    label: "Logo",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "type",
    label: "Type",
  },

  {
    id: "contactName",
    label: "Contact Name",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "email",
    label: "Email",
  },
];
function Association() {
  const [openCircle, setOpenCircle] = useState(false);
  const [dataOfTable, setDataOfTable] = useState([]);
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const Logdetails = useSelector((state) => state.login.LoginDetails);
  // const token = useSelector((state) => state.login.LoginDetails?.accessToken);

  // const headers = {
  //   Authorization: `Bearer ${token}`,
  //   "Content-Type": "application/json",
  //   Accept: "*/*",
  // };
  useEffect(() => {
    document.title = "FIG Events | Association";
    getAssoList();
    // eslint-disable-next-line
  }, [deleteInfo]);
  const getAssoList = async () => {
    let seData = {
      url: `/api/Association/GetAllAssociationList?userId=${Logdetails?.userId}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        let data = res?.data.payload?.map((x, i) => {
          let asId = x?.associationAdminList?.filter(
            (xy) => xy?.userId === Logdetails?.userId
          );
          return {
            ...x,
            id: i + 1,
            // logo: x.logo ? (
            //   <ImgPopUp
            //     img={`${Config.API_HOST_URL_live}${x.logo}`}
            //     addClass={"h_fix"}
            //     hide_icon={true}
            //   />
            // ) : (
            //   <ImgPopUp img={NotFound} addClass={"h_fix"} hide_icon={true} />
            // ),
            logo: x?.logoThumbnail ? (
              <ImgPopUp
                thumbnail={`${Config.API_HOST_URL_live}${x?.logoThumbnail}`}
                img={`${Config.API_HOST_URL_live}${x?.logo}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : x?.logo ? (
              <ImgPopUp
                img={`${Config.API_HOST_URL_live}${x?.logo}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : (
              <ImgPopUp img={NotFound} addClass={"h_fix"} hide_icon={true} />
            ),
            Action: (
              <>
                {Logdetails?.roleId === 1 || Logdetails?.roleId === 2 ? (
                  <>
                    <Tooltip title="Discount Code">
                      <Link
                        to={`/discount/Association/${x.associationId}`}
                        className="me-3 "
                      >
                        <TbDiscount2 fontSize={30} />
                      </Link>
                    </Tooltip>
                    <Link
                      to={`${x.associationId}/store`}
                      className="btn_edit me-3 d-flex align-items-center"
                    >
                      Add Store
                    </Link>
                    <Link
                      to={`edit/${x.associationId}`}
                      className="btn_edit me-3 d-flex align-items-center"
                    >
                      <VscEdit />
                    </Link>
                  </>
                ) : asId?.length > 0 ? (
                  <>
                    <Tooltip title="Discount Code">
                      <Link
                        to={`/discount/Association/${x.associationId}`}
                        className="me-3 "
                      >
                        <TbDiscount2 fontSize={30} />
                      </Link>
                    </Tooltip>
                    <Link
                      to={`${x.associationId}/store`}
                      className="btn_edit me-3 d-flex align-items-center"
                    >
                      Add Store
                    </Link>
                    <Link
                      to={`edit/${x.associationId}`}
                      className="btn_edit me-3 d-flex align-items-center"
                    >
                      <VscEdit />
                    </Link>
                  </>
                ) : (
                  ""
                )}
                {Logdetails?.roleId === 1 || Logdetails?.roleId === 2 ? (
                  <DeletePopup
                    title={"Delete Association"}
                    text={x?.name}
                    mtd={"post"}
                    url={`/api/Association/DeleteAssociation?associationId=${x?.associationId}&deletedBy=${Logdetails?.userId}`}
                  />
                ) : (
                  ""
                )}
              </>
            ),
            groupUserList:
              x?.userList?.length > 0
                ? x?.userList?.map((y, index) => {
                    return {
                      ...y,
                      id: index + 1,
                      profile: y?.userProfileImage ? (
                        <ImgPopUp
                          img={`${Config.API_HOST_URL_live}${y?.userProfileImage}`}
                          addClass={"h_fix"}
                          hide_icon={true}
                        />
                      ) : (
                        <ImgPopUp
                          img={NotFound}
                          addClass={"h_fix"}
                          hide_icon={true}
                        />
                      ),
                      type:
                        x?.associationAdminList?.filter(
                          (z) => z?.userId === y?.userId
                        )?.length > 0
                          ? "Admin"
                          : "User",
                      Action: (
                        <div className="edit_delete justify-content-start">
                          <DeletePopup
                            title={`Delete Association Member `}
                            text={y?.userName}
                            mtd={"post"}
                            url={`/api/Association/DeleteAssociationAllocationUser?associationUserAllocationId=${y?.associationUserAllocationId}&deletedBy=${Logdetails?.userId}`}
                          />
                        </div>
                      ),
                    };
                  })
                : [],
          };
        });
        setDataOfTable(data);
      }
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="association_page">
          <div className="main_wrapper">
            <div className="new_card p-0">
              <NewLIstTable
                addressLink={`create`}
                headCells={headCells}
                title="Association List"
                action={true}
                ListData={dataOfTable}
                dropdown={false}
                addBtn={true}
                // addBtn={Logdetails?.roleId === 1 ? true : ""}
                showUserListInTable={true}
                fileName={"Association Report"}
                exportDataList={true}
                innerProduct={true}
                subHeadCells={[
                  {
                    id: "id",
                    label: "#",
                  },
                  {
                    id: "profile",
                    label: "Profile",
                    exportTab: false,
                  },
                  {
                    id: "userName",
                    label: "User Name",
                  },
                  {
                    id: "userRole",
                    label: "Role",
                  },
                  {
                    id: "userEmail",
                    label: "Email",
                  },
                  {
                    id: "userPhone",
                    label: "Phone",
                  },
                  {
                    id: "type",
                    label: "Type",
                  },
                  {
                    id: "Action",
                    label: "Action",
                  },
                ]}
              />
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default Association;
