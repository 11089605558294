import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GetCallApi, PostCallApi } from "../../Action/Action";
import { ToastContainer } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { Config } from "../../Utils/Config";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { VscTrash } from "react-icons/vsc";
import uuid from "react-uuid";
import { MdCloudUpload } from "react-icons/md";
import { ToastError, ToastSuccess } from "../Components/Toast";

const NotFound = "/assets/images/Image_not_available.jpg";

function UserConferenceVideoAudioClipUploadForm() {
  const { id, uId } = useParams();
  const [openCircle, setOpenCircle] = useState(false);
  const [allDetails, setAllDetails] = useState();
  const [formSubmit, setFormSubmit] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    GetAllQuestionList();
  }, []);

  const GetAllQuestionList = async () => {
    let seData = {
      url: `/api/ConferenceTestimonialVideoOrAudio/GetConferenceVideoOrAudioDescriptionById?conferenceId=${id}`,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200 && res?.data.success) {
      let allData = res?.data?.payload;
      setAllDetails(allData);
      setValue("conferenceId", id);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const onSubmit = async (data) => {
    let is_success = true;
    if (files?.length === 0) {
      is_success = false;
      ToastError("Minimum one file required");
    }

    let sendForm = new FormData();
    let sv = { ...data, userId: uId, CreatedBy: uId };
    for (var key in sv) {
      sendForm.append(key, sv[key]);
    }
    files?.map((x) => {
      return sendForm.append("VideoOrAudioClipUrlList", x?.file);
    });

    if (is_success) {
      let seData = {
        url: `/api/ConferenceTestimonialVideoOrAudio/CreateConferenceTestimonialVideoOrAudio`,
        body: sendForm,
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200 && res?.data?.success) {
        setFormSubmit(true);
        ToastSuccess(res?.data.message);
      } else {
        ToastError(res?.data.message);
      }
    }
  };

  const triggerFileInput = () => {
    document.getElementById("file_input").click();
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (files?.length < 5) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      // const allowedTypes = ["video/*", "audio/*"];
      let dp = droppedFiles?.slice(0, 5 - files?.length);
      if (droppedFiles?.length > 5) {
        ToastError("You can upload max 5 files");
      }
      const validFiles = [];
      for (const file of dp) {
        const validVideoAudioExtensions =
          /\.(mp4|mov|mkv|wmv|ogg|mp3|wav|aac)$/i;

        if (validVideoAudioExtensions.test(file.name)) {
          let fileS = file.size;
          if (fileS < 25 * 1000 * 1024) {
            validFiles.push(file);
          } else {
            ToastError(`Invalid file Size: ${file.name} `);
          }
        } else {
          ToastError(`Invalid file type: ${file.name}`);
        }
      }
      if (validFiles.length > 0 && droppedFiles?.length > 0) {
        handleFiles(validFiles);
      } else if (droppedFiles?.length === 0) {
        ToastError("No valid video or audio files dropped");
      }
    } else {
      ToastError("You have already uploaded 5 files");
    }
    // e.target.files = null;
  };

  const handleFiles = (newFiles) => {
    const updatedFiles = [...files];
    const updatedFileNames = [...fileNames];

    newFiles.forEach((file) => {
      const fileExists = updatedFiles.some(f => f.file.name === file.name && f.file.size === file.size);

      if (fileExists) {
        // Show error message that the file has already been uploaded
        ToastError(`File already uploaded: ${file.name}`);
      } else {
        const id = uuid();
        updatedFiles.push({ id, file });
        updatedFileNames.push({ id, name: file.name });
      }
    });

    setFiles(updatedFiles);
    setFileNames(updatedFileNames);
  };

  const onFileChange = (event) => {
    if (files?.length < 5) {
      const selectedFiles = Array.from(event.target.files);
      let dp = selectedFiles?.slice(0, 5 - files?.length);
      if (selectedFiles?.length > 5) {
        ToastError("You can upload max 5 files");
      }
      const validFiles = [];
      for (const file of dp) {
        const validVideoAudioExtensions =
          /\.(mp4|mov|mkv|wmv|ogg|mp3|wav|aac)$/i;

        if (validVideoAudioExtensions.test(file.name)) {
          let fileS = file.size;
          if (fileS < 25 * 1000 * 1024) {
            validFiles.push(file);
          } else {
            ToastError(`Invalid file Size: ${file.name} `);
          }
        } else {
          ToastError(`Invalid file type: ${file.name}`);
        }
      }
      if (validFiles.length > 0 && selectedFiles?.length > 0) {
        handleFiles(validFiles);
      } else if (selectedFiles?.length === 0) {
        ToastError("No valid video or audio files dropped");
      }
      // handleFiles(selectedFiles);
      event.target.files = null;
    } else {
      ToastError("You have already uploaded 5 files");
    }
  };

  const handleDelete = (id) => {
    setFileNames(fileNames.filter((fileNameObj) => fileNameObj.id !== id));
    setFiles(files.filter((fileObj) => fileObj.id !== id));
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <section className="audio_video_clip_feedback">
        <div className="main_wrapper ">
          <div className="background_theme mb-3 ">
            <img
              src={
                allDetails?.displayImage
                  ? Config.API_HOST_URL_live + allDetails?.displayImage
                  : NotFound
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = NotFound;
              }}
              width={30}
              height={30}
              alt=""
            />
            <span>
              {allDetails?.conferenceName} Conference Member Testimonial
              Video/Audio Submission
            </span>
          </div>
          {formSubmit ? (
            <div className="success_message_box">
              <BsFillPatchCheckFill className="icon" />
              <h5 className="m-0">
                Member Testimonial Video/Audio Submitted Successfully
              </h5>
            </div>
          ) : (
            <div className="card">
              <div className="card-body">
                {" "}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    {allDetails?.videoAudioDescription ? (
                      <div className="col-md-12">
                        <div
                          className="prompts mb-4"
                          style={{
                            backgroundColor: "#77d6e6",
                            color: "white",
                            borderRadius: "10px",
                            padding: "1rem",
                          }}
                        >
                          <ul>
                            {allDetails?.videoAudioDescription
                              ?.split("\r\n")
                              ?.map((x, i) => (
                                <li key={i}>
                                  <span>{x}</span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          First Name
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter first name"
                          {...register("firstName", {
                            required: "This field is required",
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                            maxLength: {
                              value: 250,
                              message: "Max character 250 allowed",
                            },
                          })}
                        />
                        {errors.firstName && (
                          <span role="alert" className="error_text">
                            {errors.firstName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Last Name
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          {...register("lastName", {
                            required: "This field is required",
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                            maxLength: {
                              value: 250,
                              message: "Max character 250 allowed",
                            },
                          })}
                        />
                        {errors.lastName && (
                          <span role="alert" className="error_text">
                            {errors.lastName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Email
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter email"
                          {...register("email", {
                            required: "This field is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please Enter Valid Email",
                            },
                          })}
                        />
                        {errors.email && (
                          <span role="alert" className="error_text">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          School District/Organization
                          {/* <span className="red-color"> *</span> */}
                        </label>
                        <input
                          type="text"
                          placeholder="School District Name"
                          className="form-control"
                          {...register("SchoolDistrictOrOrganization", {
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors?.SchoolDistrictOrOrganization && (
                          <span role="alert" className="error_text">
                            {errors?.SchoolDistrictOrOrganization?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">
                        Files Upload (Max 5) (You can upload one file size Upto
                        25MB/25600000 bytes)
                      </label>
                      <div
                        id="cmp_docs_display"
                        className="drag_drop"
                        onClick={triggerFileInput}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                      >
                        <MdCloudUpload fontSize={40} />
                        <p className="f-16-600 mb-1">Browse Files</p>
                        <p className="f-14-400">Drag and drop here</p>
                      </div>
                      <input
                        type="file"
                        id="file_input"
                        multiple
                        accept="audio/*,video/*"
                        className="form-control"
                        style={{ display: "none" }}
                        onChange={onFileChange}
                      />
                      <input type="hidden" {...register("invoiceURL")} />
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="all_list_files">
                        {fileNames?.map((x, index) => {
                          return (
                            <div
                              className="d-flex justify-content-between align-items-center single_files"
                              key={index}
                            >
                              <span>{x?.name}</span>
                              <Link
                                className="btn_dlt d-flex align-items-center"
                                onClick={() => handleDelete(x?.id)}
                                style={{
                                  backgroundColor: "white",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <VscTrash className="icon" />
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-end mt-3">
                      <div className="form-group d-flex gap-3">
                        <Button className="common_btn" type="submit">
                          {openCircle ? (
                            <CircularProgress
                              color="inherit"
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default UserConferenceVideoAudioClipUploadForm;
