import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import { persistStore } from "redux-persist";
import "react-toastify/dist/ReactToastify.css";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-quill/dist/quill.snow.css";
import "react-tooltip/dist/react-tooltip.css";
 import HttpsRedirect from 'react-https-redirect';

// let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={store}>
      <HttpsRedirect>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
      </HttpsRedirect>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
