import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { VscEdit } from "react-icons/vsc";
import "./index.scss";
import { TbDiscount2 } from "react-icons/tb";
import AddEO from "./AddEO/index.jsx";
import TableHeader from "../../../TableHeader.json";
import { GetCallApi } from "../../../Action/Action";
import { ToastError } from "../../Components/Toast";

const NewLIstTable = lazy(() =>
  import("../../Components/NewLIstTable/index.jsx")
);
const DeletePopup = lazy(() => import("../../Components/DeletePopup"));
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ConferenceListMain() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  useEffect(() => {
    GetAllEventsList();
    document.title = "FIG Events | Conference";
    // eslint-disable-next-line
  }, [deleteInfo]);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const GetAllEventsList = async () => {
    setOpenCircle(true);
    let res = await GetCallApi({
      url: `/api/Conference/GetAllConferenceList?userId=${logindetail?.userId}&all=true`,
    });
    // Axios.get(
    //   // "/api/Events/GetAllEventsList",
    //   // `/api/Events/GetAllEventsList?userId=${logindetail?.userId}`,
    //   { headers }
    // )
    //   .then((res) => {
    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            isExternalConference: c?.isExternalConference ? "Yes" : "No",
            conferenceStartdate: c?.conferenceStartdate
              ? convert(c?.conferenceStartdate?.split("T")[0])
              : "",
            conferenceEnddate: c?.conferenceEnddate
              ? convert(c?.conferenceEnddate?.split("T")[0])
              : "",
            color:
              c?.status === "Approved"
                ? "approv"
                : c?.status === "Rejected"
                ? "reject"
                : "created",
            Action: (
              <>
                {c?.isEditByAssociationUser === true ||
                logindetail?.roleId === 1 ? (
                  <>
                    {c?.status === "Approved" ? (
                      <>
                        {logindetail?.roleId === 1 ? (
                          <AddEO conId={c.conferenceId} />
                        ) : (
                          ""
                        )}
                        <Tooltip title="Discount Code">
                          <Link
                            to={`/discount/Conference/${c.conferenceId}`}
                            className="me-3 "
                          >
                            <TbDiscount2 fontSize={30} />
                          </Link>
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                    <Link
                      to={`/conference-setup-process/${c.conferenceId}`}
                      className="btn_edit me-3 d-flex align-items-center"
                    >
                      <VscEdit />
                    </Link>
                    <DeletePopup
                      title={"Delete Confernce"}
                      text={c?.conferenceName}
                      mtd={"post"}
                      url={`/api/Conference/DeleteConference?conferenceId=${c?.conferenceId}&deletedBy=${logindetail?.userId}`}
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            ),
          };
        });
        setDataOfTable(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
      ToastError(res?.data?.message);
    }
  };

  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="new_card p-0">
                  <NewLIstTable
                    addressLink={`/conference-setup-process`}
                    headCells={TableHeader?.ConferenceListHeader}
                    title="Conference List"
                    action={true}
                    ListData={dataOfTable}
                    dropdown={false}
                    addBtn={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ConferenceListMain;
