import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import ImgPopUp from "../../../../Components/ImgPopUp/index";
import { VscEdit } from "react-icons/vsc";
import { TbDiscount2 } from "react-icons/tb";
// import Axios from "../../../../../Utils/AxiosConfig";
import { ToastError, ToastSuccess } from "../../../../Components/Toast";

const DeletePopup = lazy(() => import("../../../../Components/DeletePopup"));
const NewLIstTable = lazy(() => import("../../../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCellsProduct = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "image",
    label: "Product Image",
  },
  {
    id: "ProductName",
    label: "Product Name",
    widthFix: 600,
  },
  {
    id: "totalProductPrice",
    label: "Price",
  },
  {
    id: "associationName",
    label: "Association Name",
  },
  {
    id: "storeName",
    label: "Store Name",
  },
  {
    id: "gtinNo",
    label: "GTIN",
  },
  // {
  //   id: "upcNo",
  //   label: "UPC",
  // },
  {
    id: "Manufacturer",
    label: "Manufacturer",
  },
];
function AssociationProduct() {
  const [exDeatils, setExDetails] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
 const token = useSelector((state) => state.login.LoginDetails.accessToken);
 const { asid, stId } = useParams();
  const [syncCheck, setSyncCheck] = useState([]);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  useEffect(() => {
    document.title = "FIG Events | Association Product";
    GetProduct();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const changetbData = () => {
    let newtb = exDeatils?.map((x) => {
      return {
        ...x,
        Action: (
          <>
            <label className="personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                name="Remember Me"
                checked={
                  syncCheck?.filter((e) => e === x?.associationProductId)
                    ?.length > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleSelectSync(e?.target?.checked, x?.associationProductId)
                }
              />
              <span className="checkmark"></span>
            </label>
            <Tooltip title="Discount Code">
              <Link
                to={`${x?.associationProductId}/discount`}
                className="me-3 "
              >
                <TbDiscount2 fontSize={30} />
              </Link>
            </Tooltip>
            <div className="d-flex align-items-center justify-content-center">
              <Link
                to={`edit/${x?.associationProductId}`}
                className="btn_edit me-2 d-flex align-items-center"
              >
                <VscEdit />
              </Link>

              {/* {exDeatils?.length === 1 ? (
                ""
              ) : ( */}
              <DeletePopup
                title={"Delete Association Product"}
                text={x?.productName}
                mtd={"post"}
                url={`/api/AssociationProduct/DeleteAssociationProduct?associationProductId=${x?.associationProductId}&deletedBy=${LogDetail?.userId}`}
              />
              {/* )} */}
            </div>
          </>
        ),
      };
    });
    setExDetails(newtb);
  };

  useEffect(() => {
    if (syncCheck?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [syncCheck]);

  const GetProduct = async () => {
    setExDetails([]);
    setSyncCheck([]);
    let seData = {
      url: `/api/AssociationProduct/GetAllAssociationProductList?associationId=${asid}&associationStoreId=${stId}&userId=${LogDetail?.userId}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        let sv = res?.data.payload?.map((x, i) => {
          return {
            ...x,
            id: i + 1,
            image: x?.multipleImages?.[0]?.imagePath ? (
              <ImgPopUp
                img={x?.multipleImages?.[0]?.imagePath}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : (
              ""
            ),
            ProductName: x.productName ? x.productName : "",

            GTIN: x.gtin ? x.gtin : "",
            UPC: x.upc ? x.upc : "",
            Manufacturer: x.manufacturer ? x.manufacturer : "",
            // des: x?.shortDescription,
            Action: (
              <>
                <label className="personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={false}
                    onChange={(e) =>
                      handleSelectSync(
                        e?.target?.checked,
                        x?.associationProductId
                      )
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <Tooltip title="Discount Code">
                  <Link
                    to={`${x?.associationProductId}/discount`}
                    className="me-3 "
                  >
                    <TbDiscount2 fontSize={30} />
                  </Link>
                </Tooltip>
                <div className="d-flex align-items-center justify-content-center">
                  <Link
                    to={`edit/${x?.associationProductId}`}
                    className="btn_edit me-2 d-flex align-items-center"
                  >
                    <VscEdit />
                  </Link>

                  {/* {res?.data.payload?.length === 1 ? (
                    ""
                  ) : ( */}
                  <DeletePopup
                    title={"Delete Association Product"}
                    text={x?.productName}
                    mtd={"post"}
                    url={`/api/AssociationProduct/DeleteAssociationProduct?associationProductId=${x?.associationProductId}&deletedBy=${LogDetail?.userId}`}
                  />
                  {/* )} */}
                </div>
              </>
            ),
          };
        });
        setExDetails(sv);
      }
    }
  };


  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const handleDelete = async () => {
    let assoProductData = syncCheck?.map((x) => x);
    let seAsso = {
      url: `/api/AssociationProduct/DeleteAssociationMultipleProduct?deletedBy=${LogDetail?.userId}`,
      body: assoProductData,
      headers: headers,
    };
    let res= await PostCallApi(seAsso);
    // let res = await Axios.delete(seAsso.url, {
    //   headers: seAsso.headers,
    //   data: seAsso.body,
    // });
    if (res?.status === 200 && res?.data.success) {
      ToastSuccess(res?.data.message);
      GetProduct();
    } else {
      ToastError(res?.data.message);
    }
  };

  const handleSelectSync = (val, userId) => {
    if (val) {
      setSyncCheck((old) => [...old, userId]);
    } else {
      setSyncCheck((old) => old?.filter((data) => data !== userId));
    }
  };

  const handleSelectAll = (val, list) => {
    if (val) {
      setSyncCheck(list?.map((x) => x?.associationProductId));
    } else {
      setSyncCheck([]);
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="association-product-list py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <NewLIstTable
                  addressLink={`create`}
                  exportBtn={false}
                  title="Association Product List"
                  headCells={headCellsProduct}
                  action={true}
                  ListData={exDeatils}
                  dropdown={false}
                  addBtn={true}
                  actionFirst={true}
                  selectAllBtn={
                    LogDetail?.roleId === 1 || LogDetail?.roleId === 2
                      ? true
                      : false
                  }
                  selectingAll={handleSelectAll}
                  AddStudents={handleDelete}
                  prdAddAr={syncCheck}
                  checkBtnName={"Delete"}
                  className={"delet_button"}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AssociationProduct;
