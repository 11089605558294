import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { VscEdit } from "react-icons/vsc";
import "./index.scss";
import { GetCallApi } from "../../../Action/Action";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const Togglebutton = lazy(() => import("../../Components/Togglebutton"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "blockPostCategoryName",
    label: "Block Post Category Name",
  },
];
function PostBlockCategoryList() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  const logindetail = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Block Post Category";
    GetAllCategoryList();
    // eslint-disable-next-line
  }, []);

  const GetAllCategoryList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/BlockPostCategory/GetAllBlockPostCategoryList?userId=${logindetail?.userId}`,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res?.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,

            Action: (
              <>
                {c?.isActive && (
                  <Link
                    to={`edit/${c.blockPostCategoryId}`}
                    className="btn_edit me-3 d-flex align-items-center"
                  >
                    <VscEdit />
                  </Link>
                )}
                <Togglebutton
                  checked={c?.isActive === true ? true : false}
                  title={"Delete Post Block Category"}
                  disabled={false}
                  text={c?.blockPostCategoryName}
                  mtd={"post"}
                  url={`/api/BlockPostCategory/DeleteBlockPostCategory?blockPostCategoryId=${c?.blockPostCategoryId}&createdBy=${logindetail?.userId}`}
                  callBack={true}
                  callBackFunction={GetAllCategoryList}
                />
              </>
            ),
          };
        });
        setDataOfTable(comList);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="new_card p-0">
                  <NewLIstTable
                    addressLink={`create`}
                    headCells={headCellsEvent}
                    title="Post Block Category List"
                    action={true}
                    ListData={dataOfTable}
                    dropdown={false}
                    addBtn={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default PostBlockCategoryList;
