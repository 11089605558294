import React, { Suspense, lazy, useState, useEffect, useRef } from "react";
import "./index.scss";
import axios from "axios";
import { Config } from "../../Utils/Config";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import { PostCallApi } from "../../Action/Action";
// import { useSignalR } from "../../hooks/useSignalR";

const userimg12 = "/assets/images/user.png";
const vecteezy = "/assets/images/vecteezy.png";
const UsersList = lazy(() => import("./UsersList"));
const UserMessages = lazy(() => import("./UserMessages"));
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function Messages() {
  const { id } = useParams();
  // const {
  //   connection,
  //   // isConnected
  // } = useSignalR();
  const [searchField, setSearchField] = useState("");
  const [usList, setUsList] = useState([]);
  const [userChatDeatil, setUserChatDeatil] = useState();
  const [seaUsList, setSeaUsList] = useState([]);
  const [startNewchat, setStartNewChat] = useState(false);
  const [chatListOpen, setChatListOpen] = useState(true);
  const [typingData,] = useState(); //setTypingData
  const [userStatus,] = useState();//setUserStatus

  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    document.title = "FIG Events | Message";
    getSearchList(searchField);
    if (searchField === "") {
      getChatUsList();
    }
    // eslint-disable-next-line
  }, [searchField]);

  useEffect(() => {
    const interval = setInterval(() => getChatUsList(), 30000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const getSearchList = (name) => {
    if (name !== "") {
      if (name.length > 2) {
        axios
          .get(
            `${Config.API_HOST_URL_live}/api/Chat/SearchUserByName?userId=${LogUserId}&searchByName=${name}`,
            { headers }
          )
          .then((response) => {
            if (response?.data.success) {
              let srlist = response?.data.payload?.map((item) => {
                return {
                  isTyping: false,
                  userId: item?.userId,
                  name: item?.userName,
                  chatId:
                    item?.activeChatInfo === null ||
                      item?.activeChatInfo?.chatId === "" ||
                      item?.activeChatInfo?.chatId === null
                      ? ""
                      : item?.activeChatInfo?.chatId,
                  img:
                    item?.activeChatInfo === null ||
                      item?.activeChatInfo?.profileUrl === "" ||
                      item?.activeChatInfo?.profileUrl === null
                      ? userimg12
                      : `${Config.API_HOST_URL_live}${item?.activeChatInfo?.profileUrl}`,
                  userProfile:
                    item?.userProfile === undefined ||
                      item?.userProfile === null ||
                      item?.userProfile === ""
                      ? userimg12
                      : `${Config.API_HOST_URL_live}${item?.userProfile}`,
                };
              });

              setSeaUsList(srlist);
            }
          });
      }
    } else {
      setSeaUsList([]);
    }
  };

  const getChatUsList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/Chat/GetChatListforUser?userId=${LogUserId}`,
        { headers }
      )
      .then((response) => {
        if (response?.data.success) {
          let listUs = response?.data.payload?.map((item, i) => {
            return {
              id: i + 1,
              img:
                item?.userInfo?.profileUrl === "" ||
                  item?.userInfo?.profileUrl === null
                  ? userimg12
                  : `${Config.API_HOST_URL_live}${item?.userInfo?.profileUrl}`,
              userId:
                item?.userInfo?.userId === "" || item?.userInfo?.userId === null
                  ? ""
                  : item?.userInfo?.userId,
              chatId:
                item?.chatId === "" || item?.chatId === null
                  ? ""
                  : item?.chatId,
              name:
                item?.userInfo?.fistName === "" ||
                  item?.userInfo?.fistName === null
                  ? ""
                  : item?.userInfo?.firstName + " " + item?.userInfo?.lastName,
              time:
                item?.startedOnDate === "" || item?.startedOnDate === null
                  ? "--"
                  : new Date(item?.startedOnDate)?.toLocaleDateString("en-us", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "2-digit",
                  }),
              mestxt:
                item?.lastMessage === "" || item?.lastMessage === null
                  ? ""
                  : item?.lastMessage,
              siteLocation:
                item?.userInfo?.siteLocation === null ||
                  item?.userInfo?.siteLocation === ""
                  ? "-"
                  : item?.userInfo?.siteLocation,
              jobTitle:
                item?.userInfo?.jobTitle === null ||
                  item?.userInfo?.jobTitle === ""
                  ? "-"
                  : item?.userInfo?.jobTitle,
              schoolDistrictName:
                item?.userInfo?.schoolDistrictName === null ||
                  item?.userInfo?.schoolDistrictName === ""
                  ? "-"
                  : item?.userInfo?.schoolDistrictName,
              phone:
                item?.userInfo?.phone === null || item?.userInfo?.phone === ""
                  ? "-"
                  : item?.userInfo?.phone,
              coverPhoto:
                item?.userInfo?.coverPhoto === null ||
                  item?.userInfo?.coverPhoto === ""
                  ? vecteezy
                  : `${Config.API_HOST_URL_live}${item?.userInfo?.coverPhoto}`,
              // mesnum: "10",
              // active: true,
            };
          });
          if (startNewchat) {
            setUserChatDeatil(listUs[0]);
          } else {
            if (userChatDeatil?.chatId) {
              let useSearch = listUs?.filter(
                (x) => x.chatId === userChatDeatil?.chatId
              );
              if (useSearch[0]) {
                setUserChatDeatil(useSearch[0]);
              }
            }
          }
          setUsList(listUs);
        } else {
          setUsList([]);
          setUserChatDeatil();
        }
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      searchField === "" ? setSeaUsList([]) : <></>;
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [searchField]);

  useEffect(() => {
    if (id) {
      if (usList?.length > 0) {
        let chatD = usList?.filter((item) => item.chatId === id);
        setUserChatDeatil(chatD[0]);
      }
    }
  }, [id, usList]);

  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const handleDeleteChat = async (id) => {
    // axios
    //   .delete(
    //     `${Config.API_HOST_URL_live}/api/Chat/DeleteChatById?chatId=${id?.chatId}&deletedBy=${LogUserId}`,
    //     { headers }
    //   )
    const res = await PostCallApi({
      url: `${Config.API_HOST_URL_live}/api/Chat/DeleteChatById?chatId=${id?.chatId}&deletedBy=${LogUserId}`,
      body: {},
    })

    if (res?.status === 200) {
      if (res?.data.success) {
        if (id?.chatId === userChatDeatil?.chatId) {
          setUserChatDeatil();
        }

        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(res?.data?.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        getChatUsList();
      }
    }
  };

  // For Open/Close Chat user list
  const sidebarRef = useRef(null);
  useEffect(() => {
    const detectViewport = () => {
      if (window.innerWidth <= 768) {
        setChatListOpen(false); // Close sidebar on mobile view
      } else {
        setChatListOpen(true); // Open sidebar on laptop view
      }
    };

    detectViewport();

    // Attach event listener for window resize
    window.addEventListener("resize", detectViewport);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", detectViewport);
  }, []);

  const chatListAction = () => {
    setChatListOpen(!chatListOpen);
  };

  // useEffect(() => {
  //   if (connection) {
  //     connection?.on("UserStatusChanged", (userid, status) => {
  //       console.log(status, userid);
  //       setUserStatus({ rId: userid, status: status?.toString() });
  //     });
  //     connection.on("ReceiveTyping", (isTrue, data) => {
  //       setTypingData({ isTrue: isTrue, rId: data });
  //       setTimeout(() => {
  //         setTypingData();
  //       }, 2000);
  //     });
  //   }

  //   return () => {
  //     if (connection) {
  //       // connection.off('ReceiveMessage');
  //       connection.off("ReceiveTyping");
  //     }
  //   };
  // }, [connection]);

  let filterUserList = usList?.map((x) => {
    if (typingData?.rId === x?.userId) {
      return { ...x, isTyping: true };
    }
    if (userStatus?.rId === x?.userId) {
      return { ...x, online: userStatus?.status };
    }
    return x;
  });
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="ceu_cource_page main_backcolor">
          <div className="chat_page">
            <UsersList
              usList={filterUserList}
              filteredData={seaUsList}
              searchField={searchField}
              setSearchField={setSearchField}
              setUserChatDeatil={setUserChatDeatil}
              userChatDeatil={userChatDeatil}
              setStartNewChat={setStartNewChat}
              handleDeleteChat={handleDeleteChat}
              chatListOpen={chatListOpen}
              sidebarRef={sidebarRef}
            />
            <UserMessages
              userChatDeatil={userChatDeatil}
              chatListAction={chatListAction}
              chatListOpen={chatListOpen}
              typingData={typingData}
              userStatus={userStatus}
            />
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default React.memo(Messages);
